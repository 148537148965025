.MainMenu {
  padding: 0;
}
.MainMenuItem ~ .MainSubMenu {
  display: none;
}
.MainMenuItem.MainMenuItem_active ~ .MainSubMenu  {
  display: block;
}
/*.MainMenuItemContainer_hasSubMenu .MainMenuItem {*/
/*  border-radius: var(--menu-item-border-radius) var(--menu-item-border-radius) 0 0;*/
/*}*/
/*.HistoryMenuUnviewedMark {*/
/*  margin-left: 10px;*/
/*}*/
.MainMenuItem__bottom-line {
  height: 1px;
  background-color: var(--sidebar-border-color);
  /*margin: 0;*/
}
.MainMenuItemContainer:last-child .MainMenuItem__bottom-line {
  display: none;
}