.CustomDropDownContainer {
    position: relative;
}
.CustomDropDownContainer__header-container {

}
.CustomDropDownContainer__options-relative-container {
    position: relative;
    display: none;
    z-index: 20;
}
.CustomDropDownContainer__options-container {
    position: absolute;
    /*width: 160px;*/
    background-color: var(--btn-accent-color);
    /*border: 1px solid blue;*/
}
.CustomDropDownContainer.CustomDropDownContainer_focused .CustomDropDownContainer__options-relative-container {
    display: block;
}
/*.CustomDropDownContainer__option {*/
/*    width: 100%;*/
/*    height: var(--el-height);*/
/*    font-family: var(--main-font-family);*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    line-height: 40px;*/
/*    padding: 0 20px;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*    color: var(--main-text-color);*/
/*    cursor: pointer;*/
/*}*/
/*.CustomDropDownContainer__option:hover {*/
/*    background-color: var(--btn-del-grey-color);*/
/*    color: var(--menu-item-back-hover-color);*/
/*}*/
