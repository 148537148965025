.report-status_CREATED,
.report-status_SCHEDULED_FOR_ADJUST,
.report-status_PROCESSING {
  color: var(--card-label-color);
}
.report-status_FAILED {
  color: var(--error-color);
}
.report-status_DRAFT {
  color: var(--main-accent-color);
}
.report-status_ADJUSTED,
.report-status_PREPARED {
  color: var(--prepared-color);
}
.report-status_UPLOADED {
  color: var(--unloaded-color);
}
.report-status_CANCELLED {
  color: var(--dark-text-color);
}


.ReportCard {
  position: relative;
}
.ReportCard__left {
  min-width: 100%;
  max-width: 100%;
}
.ReportCard__left-top {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  border-bottom: 1px solid var(--list-item-inner-border-color);
  padding: calc(3 * var(--distance-unit)) calc(11 * var(--distance-unit)) calc(3 * var(--distance-unit)) calc(3 * var(--distance-unit));
}
.ReportCard__left-top-left {
  display: flex;
  min-width: 58%;
  max-width: 58%;
}
.ReportCard__left-bottom {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  padding: calc(1 * var(--distance-unit)) calc(11 * var(--distance-unit)) calc(1 * var(--distance-unit)) calc(3 * var(--distance-unit));
}
.ReportCard__id-and-checkbox {
  min-width: 64px;
  max-width: 64px;
}
.ReportCard__period-and-status {
  min-width: calc( (100% - 64px) / 2);
  max-width: calc( (100% - 64px) / 2);
}
.ReportCard__user-and-createDate {
  min-width: calc( (100% - 64px) / 2);
  max-width: calc( (100% - 64px) / 2);
}

.ReportCard__contract-and-actTemplate {
  min-width: 42%;
  max-width: 42%;
}

.ReportCard__agents {
  display: flex;
  min-width: 58%;
  max-width: 58%;
}
.ReportCard__agents > .ReportAgentLink {
  max-width: calc( (100% - 48px) / 2);
}
.ReportCard__agents > .flex {
  min-width: calc( (100% - 48px) / 2 + 48px);
  max-width: calc( (100% - 48px) / 2 + 48px);
}
.ReportCard__agents .flex .ReportAgentLink {
  max-width: calc(100% - 48px);
}
.ReportCard__agents .flex {
  min-width: 100%;
}
.ReportCard__reportTotal-docNum {
  display: flex;
  min-width: 42%;
  max-width: 42%;
}

.ReportContractLink__icon {
  width: 16px;
  height: 16px;
  background: url("./../../../../../assets/report-contract-link-icon.svg") no-repeat center / contain;
  margin-right: var(--distance-unit);
}
.ReportActTemplateLink__icon {
  width: 16px;
  height: 16px;
  background: url("./../../../../../assets/report-act-template-link-icon.svg") no-repeat center / contain;
  margin-right: var(--distance-unit);
}

.ReportCard__right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 64px;
  max-width: 64px;
  border-left: 1px solid var(--list-item-inner-border-color);
}
.ReportCard__reportTotal,
.ReportCard__docNum {
  min-width: 50%;
  max-width: 50%;
}
.ReportCard__1C-doc-name-icon {
  width: 32px;
  height: 16px;
  background: url("./../../../../../assets/1c-doc-name-icon.svg") no-repeat center / contain;
}

@media (max-width: 1200px) {
  .ReportCard__left-top {
    display: block;
  }
  .ReportCard__left-top-left {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: calc(1 * var(--distance-unit));
  }
  .ReportCard__contract-and-actTemplate {
    min-width: 100%;
    max-width: 100%;
    padding-left: calc(8 * var(--distance-unit));
  }
  .ReportCard__left-bottom {
    padding-left: calc(11 * var(--distance-unit));
  }
  .ReportCard__agents {
    display: block;
    min-width: 50%;
    max-width: 50%;
  }
  .ReportCard__reportTotal-docNum {
    display: block;
    min-width: 50%;
    max-width: 50%;
  }
  .ReportCard__agents > div {
    max-width: 100%;
  }
  .ReportCard__agents > .ReportAgentLink {
    margin-bottom: 4px;
  }
  .ReportCard__reportTotal {
    margin-bottom: 4px;
  }
  .ReportCard .ArrowIcon {
    margin-left: 0;
  }
  .ReportCard__reportTotal, .ReportCard__docNum {
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 1000px) {
  .ReportCard__right {
    position: static;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    border-top: 1px solid var(--list-item-inner-border-color);
    border-left: unset;
  }
  .ReportCard__left-top {
    padding: calc(3 * var(--distance-unit)) calc(3 * var(--distance-unit)) calc(1 * var(--distance-unit)) calc(3 * var(--distance-unit));
  }
  .ReportCard__left-bottom {
    padding: calc(1 * var(--distance-unit)) calc(3 * var(--distance-unit)) calc(1 * var(--distance-unit)) calc(11 * var(--distance-unit));
  }
  .ReportCard__user-and-createDate {
    min-width: 100%;
    max-width: 100%;
    padding-left: calc(8 * var(--distance-unit));
    margin-top: var(--distance-unit);
  }
  .ReportCard__left-top-left {
    flex-wrap: wrap;
  }
  .ReportCard__left-bottom {
    display: block;
  }
  .ReportCard__agents {
    margin-bottom: var(--distance-unit);
    min-width: 100%;
    max-width: 100%;
  }
  .ReportCard__reportTotal-docNum {
    min-width: 100%;
    max-width: 100%;
  }
}