.Sheet__calculated-items-header {
    background-color: var(--back-hover-color);
    height: 40px;
    padding: 0 calc(2 * var(--distance-unit));
    display: flex;
    border-radius: 4px;
}
.Sheet__calculated-items-header-addServiceTypeId {
    /*min-width: 610px;*/
    /*max-width: 610px;*/
    min-width: calc(100% - 136px - 160px - 100px - 4*var(--distance-unit));
    max-width: calc(100% - 136px - 160px - 100px - 4*var(--distance-unit));
}
.Sheet__calculated-items-header-price {
    min-width: 136px;
    max-width: 136px;
}
.Sheet__calculated-items-header-number {
    min-width: 160px;
    max-width: 160px;
}
.Sheet__calculated-items-header-amount {
    min-width: 100px;
    max-width: 100px;
}
.SheetCalculatedItem__addServiceTypeId {
    /*min-width: 580px;*/
    /*max-width: 580px;*/
    min-width: calc(100% - 34px - 8px - 138px - 118px - 150px - 48px);
    max-width: calc(100% - 34px - 8px - 138px - 118px - 150px - 48px);
}
.SheetCalculatedItem__price {
    min-width: 130px;
    max-width: 130px;
}
.SheetCalculatedItem__number {
    min-width: 110px;
    max-width: 110px;
}
.SheetCalculatedItem__amount {
    min-width: 150px;
    max-width: 150px;
}
.SheetCalculatedItemFormGroup__outputMode-container {
    position: relative;
    line-height: 40px;
    width: 100%;
}
.SheetCalculatedItemFormGroup__outputMode {
    line-height: 40px;
    width: 100%;
    text-align: right;
}
.SheetCalculatedItem {
    display: flex;
    width: 100%;
}
.SheetCalculatedItem .DnD-ico-container,
.SheetCalculatedItem .BtnDel {
    opacity: 1;
    cursor: pointer;
}

.SheetCalculatedItemView {
    display: flex;
    border-radius: 4px;
    padding: 0 calc(2 * var(--distance-unit));
    line-height: 40px;
    height: 40px;
}
.SheetCalculatedItemView.SheetCalculatedItemView_even {
    background-color: var(--item-back-color);
}
.SheetCalculatedItemView__addServiceTypeId {
    /*min-width: 560px;*/
    /*max-width: 560px;*/
    min-width: calc(100% - 130px - 110px - 230px - 12px);
    max-width: calc(100% - 130px - 110px - 230px - 12px);
}
.SheetCalculatedItemView__price {
    display: flex;
    min-width: 130px;
    max-width: 130px;
    justify-content: flex-end;
}
.SheetCalculatedItemView__number {
    min-width: 110px;
    max-width: 110px;
}
.SheetCalculatedItemView__amount {
    min-width: 230px;
    max-width: 230px;
}
.SheetView .Sheet__calculated-items-header-amount {
    text-align: right;
}

.Sheet__currency-select {
    width: var(--half-card-width);
    max-width: var(--half-card-width);
}

/*.AddServicesCard .Sheet__calculated-items-header-addServiceTypeId {*/
/*    min-width: 310px;*/
/*    max-width: 310px;*/
/*}*/
/*.AddServicesCard .SheetCalculatedItemView__addServiceTypeId {*/
/*    min-width: 250px;*/
/*    max-width: 250px;*/
/*}*/

@media (max-width: 1200px) {
    .Sheet__calculated-items-header-addServiceTypeId {
        min-width: calc(100% - 100px - 120px - 100px - 4*var(--distance-unit));
        max-width: calc(100% - 100px - 120px - 100px - 4*var(--distance-unit));
    }
    .Sheet__calculated-items-header-price {
        min-width: 100px;
        max-width: 100px;
    }
    .Sheet__calculated-items-header-number {
        min-width: 120px;
        max-width: 120px;
    }
    .Sheet__calculated-items-header-amount {
        min-width: 100px;
        max-width: 100px;
    }

    .SheetCalculatedItem__addServiceTypeId {
        min-width: calc(100% - 8px - 108px - 68px - 150px - 48px);
        max-width: calc(100% - 8px - 108px - 68px - 150px - 48px);
    }
    .SheetCalculatedItem__price {
        min-width: 100px;
        max-width: 100px;
    }
    .SheetCalculatedItem__number {
        min-width: 60px;
        max-width: 60px;
    }
    .SheetCalculatedItem__amount {
        min-width: 150px;
        max-width: 150px;
    }

    .SheetCalculatedItem .DnD-ico-container {
        display: none;
    }

    .SheetCalculatedItemView__addServiceTypeId {
        /*min-width: 560px;*/
        /*max-width: 560px;*/
        min-width: calc(100% - 130px - 70px - 190px - 12px);
        max-width: calc(100% - 130px - 70px - 190px - 12px);
    }
    .SheetCalculatedItemView__price {
        display: flex;
        min-width: 130px;
        max-width: 130px;
        justify-content: flex-end;
    }
    .SheetCalculatedItemView__number {
        min-width: 70px;
        max-width: 70px;
    }
    .SheetCalculatedItemView__amount {
        min-width: 190px;
        max-width: 190px;
    }
}
@media (max-width: 900px) {
    .Sheet__calculated-items-header {
        display: none;
    }
    .SheetCalculatedItem {
        flex-wrap: wrap;
        border-bottom: 1px solid var(--list-item-inner-border-color);
        padding-bottom: var(--distance-unit);
    }
    .SheetCalculatedItem__addServiceTypeId {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: var(--distance-unit);
    }
    .SheetCalculatedItem__price {
        min-width: calc(100% - 15% - 150px - 48px - 2*var(--distance-unit));
        max-width: calc(100% - 15% - 150px - 48px - 2*var(--distance-unit));
    }
    .SheetCalculatedItem__number {
        min-width: 15%;
        max-width: 15%;
    }
    .SheetCalculatedItemView {
        flex-wrap: wrap;
        height: auto;
    }
    .SheetCalculatedItemView__addServiceTypeId {
        min-width: 100%;
        max-width: 100%;
    }
    .SheetCalculatedItemView__price {
        min-width: 40%;
        max-width: 40%;
        justify-content: flex-start;
    }
    .SheetCalculatedItemView__number {
        min-width: 20%;
        max-width: 20%;
    }
    .SheetCalculatedItemView__amount {
        min-width: 40%;
        max-width: 40%;
    }

    .Sheet__currency-select {
        width: 50%;
        max-width: 50%;
    }

}
