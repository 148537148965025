.DocumentIndexStatusIcon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.DocumentIndexStatusIcon__icon {
    width: 16px;
    height: 16px;
}
.DocumentIndexStatusIcon_INDEXED .DocumentIndexStatusIcon__icon {
    background: url("./../../../../assets/document-index-status-indexed-icon.svg") no-repeat center / contain;
}
.DocumentIndexStatusIcon_NO_CONTENT .DocumentIndexStatusIcon__icon,
.DocumentIndexStatusIcon_PENDING .DocumentIndexStatusIcon__icon,
.DocumentIndexStatusIcon_NOT_APPLICABLE .DocumentIndexStatusIcon__icon {
    background: url("./../../../../assets/document-index-status-not-indexed-icon.svg") no-repeat center / contain;
}
.DocumentIndexStatusIcon_ERROR .DocumentIndexStatusIcon__icon {
    background: url("./../../../../assets/document-index-status-error-icon.svg") no-repeat center / contain;
}