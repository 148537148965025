.AddServiceView {
    padding-top: calc(15*var(--distance-unit));
}
.NoShadowCard {
    border-radius: 10px;
    border: 1px solid var(--list-item-inner-border-color);
    background-color: #ffffff;
}
.AddServicesCard {
    max-width: var(--content-width);
}
.AddServicesCard.AddServicesCard_checked {
    background-color: var(--back-hover-color);
}
.AddServicesCard__top {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--list-item-inner-border-color);
}
.AddServicesCard.AddServicesCard_checked .AddServicesCard__top {
    border-bottom: 1px solid var(--main-accent-color);
}
.NoShadowCard.AddServicesCard.AddServicesCard_checked {
    border: 1px solid var(--main-accent-color);
}
.AddServicesCard__bottom {
    padding: calc(3 * var(--distance-unit));
}
.AddServicesCard__top-left {
    width: 100%;
    max-width: 100%;
    padding: calc(3 * var(--distance-unit));
}
.AddServicesCard__top-right {
    width: 64px;
    border-left: 1px solid var(--list-item-inner-border-color);
}
.AddServicesCard__file {
    display: flex;
}
.AddServicesCard__file-ico {
    display: block;
}
.AddServicesCard__totalAmount {
    display: flex;
    justify-content: flex-end;
}
.AddServicesCard__sheets-container {
    margin-top: calc(4 * var(--distance-unit));
}