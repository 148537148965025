.FullSizeButton {
  width: 100%;
  height: var(--card-btn-height);
  border-radius: var(--border-radius);
  padding: calc(3*var(--distance-unit));
  text-align: center;
  border: 1px dashed var(--sec-text-color);
  color: var(--sec-text-color);
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  cursor: pointer;
}
.FullSizeButton:hover {
  color: var(--main-accent-color);
  border: 1px dashed var(--main-accent-color);
  background-color: var(--item-back-hover-color);
}
.FullSizeButton.FullSizeButton_h40 {
  height: 40px;
  padding: 0;
  line-height: 40px;
}
