.DateRangePickerInput__withBorder {
  border-radius: var(--border-radius);
  border: 1px solid var(--form-field-border-color);
}
.DateRangePickerInput {
  background-color: transparent;
}
.DateInput {
  background: transparent;
  width: 90px;
}
.DateRangePickerInput_arrow_svg {
  width: 12px;
  height: 12px;
}
.DateInput_input {
  background-color: transparent;
  line-height: 16px;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--dark-text-color);
  padding: 11px 8px 9px;
}
.DateRangePickerInput_calendarIcon {
  margin: 0;
  padding: 10px 8px 8px;
}
.DateRangePicker_picker {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}
.DayPicker__withBorder {
  box-shadow: var(--select-box-shadow);
}
.FormDateRangePicker_in_Filters .DateRangePickerInput {
  background-color: var(--item-back-color);
}
.FormDateRangePicker_in_Filters .DateInput_input {
  color: var(--main-accent-color);
}
.FormDateRangePicker_in_Filters .DateRangePickerInput__withBorder {
  border-radius: 0;
  border: 1px solid transparent;
}
.FormDateRangePicker_in_Filters.FormDateRangePicker_in_Filters_static-mode .DateRangePickerInput__withBorder {
  border-radius: var(--filter-select-btn-border-radius);
  padding-right: calc(2*var(--distance-unit));
}
.FormDateRangePicker__CalendarInfo {
  display: flex;
  padding: 0 7px 16px;
}
.DateInput_input, .react-datepicker__view-calendar-icon input{
  width:100%;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select){
  background:#fff;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
  border-bottom-color:#fff
}
.react-datepicker{
  border:0;
  background: #fff;
  border-radius: 3px;
  box-shadow: var(--select-box-shadow);
  width: 625px;
  padding-top: 13px;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select){
  background:#fff;
  padding:22px 0 11px;
  border-bottom: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
  border-bottom-color:#fff
}
.react-datepicker__navigation{
  width:37px;
  height:31px;
}
.react-datepicker__navigation-icon{
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  right:0;
}
.react-datepicker__navigation-icon:hover{
  background: #f2f2f2;
}
.react-datepicker__navigation-icon::before{
  top:9px;
  left:calc(50% - 6px);
  right:auto;
}
.react-datepicker__current-month{
  font-size: 18px;
  display: block;
  margin-top:-10px;
  padding-bottom:10px;
}
.react-datepicker__day-name{
  width: 39px;
  margin: 0;
}
.react-datepicker__day{
  width: 39px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff;
  vertical-align: middle;
  margin: 0;
}
.react-datepicker__day:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit;
}
.react-datepicker__day:empty{
  border:0;
  pointer-events: none;
}
.react-datepicker__month-container{
  padding:0 13px 13px;
}
