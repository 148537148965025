.FixedHeader {
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  right: 0;
  z-index: 4000;
  height: var(--header-height);
  background-color: var(--new-back-color);
  /*box-shadow: 0 20px 40px 0 rgba(0,0,0,0.05);*/
  /*display: flex;*/
  /*align-items: center;*/
  padding-left: var(--sidebar-right-margin);
  transition: left .268s ease-out;
}
.FixedSubHeader {
  position: fixed;
  top: var(--header-height);
  left: var(--sidebar-width);
  right: 0;
  z-index: 2000;
  height: var(--subheader-height);
  background-color: var(--new-back-color);
  padding-left: var(--sidebar-right-margin);
  transition: left .268s ease-out;
}
.FixedSubHeaderContent {
  width: 100%;
  max-width: var(--wide-content-width);
  height: var(--subheader-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FixedSubHeaderContent__left, .FixedSubHeaderContent__right {
  display: flex;
  align-items: center;
  min-height: 100%;
  border-bottom: 1px solid var(--sidebar-border-color);
}
.FixedSubHeaderContent__left {
  width: var(--subheader-left-width);
}
.FixedSubHeaderContent__right {
  flex-grow:1;
  display: flex;
  justify-content: space-between;
}
.FixedSubHeaderContent__left + .FixedSubHeaderContent__right {
  margin-left: var(--subheader-left-margin);
}

.Layout__Compact .FixedSubHeader,
.Layout__Compact .FixedHeader {
  left: var(--sidebar-small-width);
}

.FixedSubHeaderContentBack {
  font-size: 12px;
}