.HeaderContent {
  width: 100%;
  max-width: var(--wide-content-width);
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--sidebar-border-color);
}
.HeaderContent__left-div {
  margin-right: auto;
  /*padding-top: calc(3*var(--distance-unit));*/
  max-width: calc(100% - 300px);
}
.HeaderContentSubtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.HeaderContent__left-div .NHeading2 {
  max-width: 100%;
  /*max-height: 44px;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
}
.SecText.SecText_in_HeaderContent {
  font-family: var(--heading-font-family);
  color: var(--sec-text-soft-color);
  line-height: 19px;
}
/*.HeaderContent button,*/
.HeaderContent .button-wrapper-link {
  align-self: flex-start;
}