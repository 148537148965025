.Text4 {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--card-label-color);
}
.Text4.Text4_fs10 {
  font-size: 10px;
}
.Text4.Text4_lh20 {
  line-height: 20px;
}
.Text4.Text4_linkColorOnHover:hover {
  color: var(--main-accent-color);
}
.Text4.Text4_linkColor {
  color: var(--main-accent-color);
}
.Text4.Text4_linkColor:hover {
  text-decoration: underline;
}
.Text4.Text4_error {
  color: var(--error-color);
}
.Text4.Text4_ENABLED {
  color: var(--alert-success-color);
}
