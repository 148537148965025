.Text3 {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--card-label-color);
}
.Text3.Text3_lh40 {
    line-height: 40px;
}
.Text3.Text3_lh24 {
    line-height: 24px;
}
.Text3.Text3_SecTextColor {
    color: var(--sec-text-color);
}
.Text3.Text3__btn {
    cursor: pointer;
    color: var(--main-accent-color);
}
.Text3.Text3__btn:hover {
    text-decoration: underline;
}