.MainHeaderBtn {
    background-color: var(--main-accent-color);
    border-radius: 0;
    height: var(--header-height);
    font-family: var(--heading-font-family);
    color: var(--btn-accent-color);
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(2*var(--distance-unit));
    min-width: 134px;
}
.MainHeaderBtn:hover {
    background-color: var(--btn-accent-hover-color);
}
.MainHeaderBtn:disabled {
    opacity: .5;
    cursor: unset;
}
.MainHeaderBtn:disabled:hover {
    background-color: var(--main-accent-color);
}
.MainHeaderBtn.MainHeaderBtn_secondary {
    background-color: var(--sidebar-border-color);
    color: var(--main-accent-color);
}
.MainHeaderBtn.MainHeaderBtn_secondary-transparent {
    background-color: transparent;
    color: var(--main-accent-color);
}
.MainHeaderBtn.MainHeaderBtn_secondary:disabled:hover {
    background-color: var(--sidebar-border-color);
    color: var(--main-accent-color);
}