.BtnEdit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--el-height);
    height: var(--el-height);
    border-radius: var(--border-radius);
    background-color: var(--btn-back-color);
    cursor: pointer;
}
.BtnEdit:hover {
    background-color: var(--item-back-hover-color);
}
.BtnEdit__ico-container {
    width: 16px;
    height: 16px;
    display: block;
    background: url("./../../../../assets/v3-btn-edit-ico.svg") no-repeat center / contain;
}
.BtnEdit_cardButtonMode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    border-radius: 0;
    background-color: var(--back-hover-color);
    cursor: pointer;
}
.BtnEdit_cardButtonMode:hover {
    background-color: var(--main-accent-color);
}
.BtnEdit_cardButtonMode .BtnEdit__ico-container {
    background: url("./../../../../assets/v4-btn-edit-ico.svg") no-repeat center / contain;
}
.BtnEdit_cardButtonMode:hover .BtnEdit__ico-container {
    background: url("./../../../../assets/v4-btn-edit-ico-hover.svg") no-repeat center / contain;
}
.BtnEdit_cardButtonMode__link-container {
    display: block;
    height: 50%;
}
.BtnEdit_cardButtonMode__link-container .BtnEdit_cardButtonMode {
    height: 100%;
}
.NoShadowCard .BtnEdit_cardButtonMode {
    border-radius: 0 10px 0 0;
}
.BtnEdit_tagExpressionMode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
}
.BtnEdit_tagExpressionMode .BtnEdit__ico-container {
    background: url("./../../../../assets/v4-btn-edit-grey-ico.svg") no-repeat center / contain;
}
.BtnEdit_tagExpressionMode.BtnEdit_tagExpressionMode_dropDownShown .BtnEdit__ico-container,
.BtnEdit_tagExpressionMode:hover .BtnEdit__ico-container {
    background: url("./../../../../assets/v4-btn-edit-ico.svg") no-repeat center / contain;
}