.HistoryLog__section {
  display: flex;
}
.HistoryLog__section-title {
  min-width: 160px;
  max-width: 160px;
  padding-left: 60px;
  padding-top: calc(2*var(--distance-unit));
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.HistoryLog__section-title.HistoryLog__section-title_updated {
  color: var(--history-updated-color);
}
.HistoryLog__section-title.HistoryLog__section-title_created {
  color: var(--rule-active-color);
}
.HistoryLog__section-title.HistoryLog__section-title_deleted {
  color: var(--history-deleted-color);
}
.HistoryLog__section-logs {
  max-width: calc(100% - 192px);
}
.HistoryLog__changedDataValue {
  border-bottom: 1px solid var(--header-border-color);
  padding: calc(2*var(--distance-unit)) 0;
  /*width: 816px;*/
  /*max-width: 816px;*/
  width: 100%;
  max-width: 100%;
}
.HistoryLog__changedDataValue.HistoryLog__changedDataValue_updated {
  display: flex;
  flex-wrap: wrap;
}
.HistoryLogPropNameValue {
  max-width: 100%;
}
.HistoryLogPropNameValue__name {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--card-label-color);
}
.HistoryLogPropNameValue__value {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--card-label-color);
}
.HistoryLog__section:last-child .HistoryLog__changedDataValue:last-child {
  border-bottom: unset;
}
