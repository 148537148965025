.WarningContainer {
    background-color: var(--modal-delete-btn-color);
    padding: calc(3 * var(--distance-unit)) calc(2 * var(--distance-unit));
}
.WarningContainer__title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--modal-delete-btn-label-color);
    margin-bottom: 4px;
}
.WarningContainer__text {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--modal-delete-btn-label-color);
}