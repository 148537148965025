.GatesParams__filters-container {
    z-index: 2000;
    top: calc(var(--header-height) + var(--subheader-height) + 57px);
    padding-top: var(--distance-unit);
    background-color: var(--new-back-color);
    max-width: var(--wide-content-width);
    width: 100%;
}
.GatesParamsCard__header {
    display: flex;
}
.GatesParamsCard__headerLeft {
    display: flex;
    min-width: 56%;
    width: 56%;
    max-width: 56%;
}
.GatesParamsCard__headerRight {
    display: flex;
    min-width: 44%;
    width: 44%;
    max-width: 44%;
}
.GatesParamsCard__headerLeftContainer {
    display: flex;
    min-width: calc(100% - 64px);
    width: calc(100% - 64px);
    max-width: calc(100% - 64px);
}
.GatesParamsCard__bottom {
    display: flex;
    justify-content: space-between;
}
.GatesParamsCard__gateId {
    min-width: 22%;
    width: 22%;
    max-width: 22%;
}
.GatesParamsCard__source {
    min-width: 35%;
    width: 35%;
    max-width: 35%;
}
.GatesParamsCard__currency {
    min-width: 43%;
    width: 43%;
    max-width: 43%;
}
.GatesParamsCard__gateName {
    min-width: 67%;
    width: 67%;
    max-width: 67%;
}
.GatesParamsCard__gateStatus {
    min-width: 33%;
    width: 33%;
    max-width: 33%;
}


.GatesParamsCard__terminal {
    display: flex;
}
.GatesParamsCard__terminalRowLeft {
    display: flex;
    min-width: 56%;
    width: 56%;
    max-width: 56%;
}
.GatesParamsCard__terminalRowRight {
    display: flex;
    min-width: 44%;
    width: 44%;
    max-width: 44%;
}

.GatesParamsCard__terminalStatus {
    display: flex;
    align-items: center;
    height: 20px;
    max-height: 20px;
    min-width: 40px;
    width: 40px;
    max-width: 40px;
}
.GatesParamsCard__terminalId {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
}
.GatesParamsCard__groupFinTerms {
    display: flex;
    min-width: calc(100% - 40px - 120px - 56px);
    width: calc(100% - 40px - 120px - 56px);
    max-width: calc(100% - 40px - 120px - 56px);
}

.GatesParamsCard__groupFinTermsLinks {
    min-width: 70%;
    width: 70%;
    max-width: 70%;
}
.GatesParamsCard__groupFinTermsEditBtn {
    min-width: 30%;
    width: 30%;
    max-width: 30%;
}
.GatesParamsCard__alertIcon {
    min-width: 56px;
    width: 56px;
    max-width: 56px;
    display: flex;
    align-items: center;
    height: 20px;
    max-height: 20px;
}
.GatesParamsCardComment {
    display: flex;
    align-items: center;
    min-width: calc(100% - 160px);
    width: calc(100% - 160px);
    max-width: calc(100% - 160px);
}
.GatesParamsCard__ignore {
    min-width: 160px;
    width: 160px;
    max-width: 160px;
    display: flex;
    justify-content: right;
    align-items: center;
}



.GatesParamsCard__dates {
    display: flex;
    margin-top: 12px;
}
.GatesParamsCard__gateCreationDate {
    display: flex;
    margin-right: 40px;
    /*min-width: 180px;*/
    /*width: 180px;*/
    /*max-width: 180px;*/
}
.GatesParamsCard__gateChangeDate {
    display: flex;
    /*min-width: 194px;*/
    /*width: 194px;*/
    /*max-width: 194px;*/
}

.GatesParamsCard__gateIgnoreLabel {
    /*min-width: 120px;*/
    /*width: 120px;*/
    /*max-width: 120px;*/
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-right: 24px;
}

.GatesParamsCard__groupFinTerm {
    width: 100%;
    max-width: 100%;
}
.GatesParamsCard__groupFinTerm_notFound {
    opacity: .5;
}

.GatesParamsCard__ignore .BtnIgnore{
    margin-top: -2px;
    margin-bottom: -2px;
}
.GatesParamsCard__alertLabel {
    min-width: 150px;
    width: 150px;
    max-width: 150px;
}
.GatesParamsCard__isIgnoredIcon {
    width: 16px;
    height: 16px;
}
.GatesParamsCard__isIgnoredIcon_false {
    background: url("./../../../../../../assets/isNotIgnored-icon.svg") center / contain no-repeat;
}
.GatesParamsCard__isIgnoredIcon_true {
    background: url("./../../../../../../assets/isIgnored-icon.svg") center / contain no-repeat;
}
.SelectFinTermButtonModal__finTerms {
    /*max-height: calc(100vh - 2 * (24px + 60px + 16px) - 70px - 72px - 112px);*/
    max-height: calc(100vh - 2 * (5vh + 24px + 16px ) - 30px - 88px - 24px - 30px - 40px);
    min-height: calc(100vh - 2 * (5vh + 24px + 16px ) - 30px - 88px - 24px - 30px - 40px);
    overflow-y: scroll;
    margin: calc(2 * var(--distance-unit)) 0;
}
.GatesParamsCard__alert {
    display: flex;
    height: var(--el-height);
    border-radius: var(--card-border-radius);
    align-items: center;
    padding: 0 calc(3 * var(--distance-unit));
    min-width: 100%;
}
.GatesParamsCard__alert.GatesParamsCard__alert_isIgnored {
    /*background-color: var(--item-back-color);*/
}
.GatesParamsCard__alert.GatesParamsCard__alert_isIgnored .GatesParamsCard__alertLabel.Text1.Text1_error{
    color: var(--card-label-color);
}



.GatesParamsCardComment__addBtn {
    cursor: pointer;
    width: 16px;
    min-width: 16px;
    height: 16px;
    background: url("./../../../../../../assets/add-filter-btn-icon.svg") center / contain no-repeat;
}
.GatesParamsCardComment__addBtn:hover {
    background: url("./../../../../../../assets/add-filter-btn-hover-icon.svg") center / contain no-repeat;
}
.GatesParamsCardComment__ico {
    width: 16px;
    min-width: 16px;
    height: 16px;
    background: url("./../../../../../../assets/comment-icon.svg") center / contain no-repeat;
}
.GatesParamsCardComment__title {
    display: flex;
    position: relative;
    align-items: center;
    padding-top: 16px;
    margin-top: -16px;
    width: 100%;
}
.GatesParamsCardComment__tooltip {
    display: none;
    position: absolute;
    left: 0;
    bottom: 32px;
    z-index: 3000;
    width: 408px;
    padding: 16px;
    background-color: var(--btn-accent-color);
    border: 1px solid var(--card-label-color);
    border-radius: 2px;
}
.GatesParamsCardComment__tooltipAngle {
    display: none;
    width: 20px;
    height: 20px;
    background-color: var(--btn-accent-color);
    border-right: 1px solid var(--card-label-color);
    border-bottom: 1px solid var(--card-label-color);
    position: absolute;
    left: 30px;
    z-index: 3001;
    transform: rotate(45deg);
    bottom: -11px;
}
.GatesParamsCardComment__title:hover .GatesParamsCardComment__tooltip,
.GatesParamsCardComment__title:hover .GatesParamsCardComment__tooltipAngle {
    display: block;
}


@media (max-width: 1180px) {
    .GatesParamsCard__terminal {
        display: block;
    }
    .GatesParamsCard__terminalRowLeft {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
    .GatesParamsCard__terminalRowRight {
        margin-top: calc(2 *var(--distance-unit));
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
    .GatesParamsCard__alertIcon {
        justify-content: right;
    }
    .GatesParamsCard__header {
        display: block;
    }
    .GatesParamsCard__headerLeft {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
    .GatesParamsCard__headerRight {
        margin-top: calc(2 *var(--distance-unit));
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
    .GatesParamsCard__gateId {
        min-width: 35%;
        width: 35%;
        max-width: 35%;
    }
    .GatesParamsCard__source {
        min-width: 35%;
        width: 35%;
        max-width: 35%;
    }
    .GatesParamsCard__currency {
        min-width: 30%;
        width: 30%;
        max-width: 30%;
    }
}


@media (max-width: 1050px) {
    .GatesParamsCard__bottom {
        display: block;
    }
}