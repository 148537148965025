.FinTermParamsRow {
  border-radius: var(--border-radius);
  padding: calc(2*var(--distance-unit)) calc(3*var(--distance-unit));
  background-color: var(--item-back-color);
  display: flex;
}
.FinTermParamsRow.FinTermParamsRow_readonly {
  justify-content: space-between;
}

.FinTermParamIco {
  color: var(--main-text-color);
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: 40px;
  padding: 12px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

}
@media (max-width: 900px) {
  .FinTermParamsRow.FinTermParamsRow_BY_TX_COUNT_COMPLEX,
  .FinTermParamsRow.FinTermParamsRow_BY_TX_COUNT_SIMPLE,
  .FinTermParamsRow.FinTermParamsRow_BY_VOLUME_SIMPLE,
  .FinTermParamsRow.FinTermParamsRow_BY_VOLUME_COMPLEX {
    flex-wrap: wrap;
  }
}