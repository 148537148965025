.TextActionButton {
    height: var(--el-height);
    min-width: 162px;
    border-radius: 2px;
    background-color: var(--item-back-color);
    padding: 0 calc(3*var(--distance-unit));
    text-align: center;
    color: var(--main-accent-color);
    font-family: var(--heading-font-family);
    font-size: 10px;
    font-weight: 600;
    line-height: var(--el-height);
    text-transform: uppercase;
    cursor: pointer;
}
.TextActionButton:disabled {
    background-color: var(--header-border-color);
    color: var(--btn-disabled-color);
    cursor: unset;
}
.TextActionButton.ResetCheckboxSelect {
    background-color: var(--back-hover-color);
    border-radius: 4px;
    width: 100%;
    font-family: var(--main-font-family);
    font-size: 12px;
    font-weight: bold;
}
.TextActionButton.ResetCheckboxSelect:hover {
    opacity: .7;
    background-color: var(--back-hover-color);
    color: var(--main-accent-color);
}
.TextActionButton.ApplyCheckboxSelect {
    background-color: var(--main-accent-color);
    color: var(--btn-accent-color);
    border-radius: 4px;
    width: 100%;
    font-family: var(--main-font-family);
    font-size: 12px;
    font-weight: bold;
}
.TextActionButton.ApplyCheckboxSelect:hover {
    opacity: .7;
    background-color: var(--main-accent-color);
    color: var(--btn-accent-color);
}
.TextActionButton.TextActionButton_in_InfoPanel {
    background-color: var(--btn-del-grey-color);
    min-width: auto;
    padding: 0 calc(2*var(--distance-unit));
    font-size: 14px;
    font-weight: normal;
    text-transform: unset;
    font-family: var(--main-font-family);
}
.TextActionButton.TextActionButton_in_InfoPanel:disabled {
    background-color: transparent;
    color: var(--card-label-color);
}
.TextActionButton:hover {
    background-color: var(--main-accent-color);
    color: var(--btn-accent-color);
}