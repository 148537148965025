.SourceParam_procIdsExcluded,
.SourceParam_mngrMercIdsExcluded,
.SourceParam_mngrIdsExcluded,
.SourceParam_mercIdsExcluded {
    display: flex;
    flex-flow:row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-top: -2px;
}
.SourceParam_procIdsExcluded .NLabel_empty,
.SourceParam_mngrMercIdsExcluded .NLabel_empty,
.SourceParam_mngrIdsExcluded .NLabel_empty,
.SourceParam_mercIdsExcluded .NLabel_empty {
    display: none;
}

.SourceParam_procIdsExcluded .CheckboxInput,
.SourceParam_mngrMercIdsExcluded .CheckboxInput,
.SourceParam_mngrIdsExcluded .CheckboxInput,
.SourceParam_mercIdsExcluded .CheckboxInput {
    padding-bottom: 0;
    padding-top: 0;
    margin-right: 12px;
}
