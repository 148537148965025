.Checkbox {
  min-width: 18px;
  width: 18px;
  height: 18px;
  border: 2px solid var(--label-color);
  border-radius: 2px;
  cursor: pointer;
}
.CheckboxInput_readOnly_gray .Checkbox{
  opacity: .5;
}
.Checkbox.Checkbox_checked {
  border: unset;
  background: url("./../../../../assets/v3-white-checkbox-ico.svg") no-repeat center / contain;
}
.CheckboxInput_readOnly_gray .Checkbox.Checkbox_checked {
  border: unset;
  background: url("./../../../../assets/v3-white-checkbox-ico-gray.svg") no-repeat center / contain;
}
.Checkbox.Checkbox_checkedSome {
  border: unset;
  background: url("./../../../../assets/checkbox-some.svg") no-repeat center / contain;
}
.Checkbox.Checkbox_16 {
  height: 16px;
  width: 16px;
  min-width: 16px;
}
.Checkbox.Checkbox_12 {
  height: 12px;
  width: 12px;
  min-width: 12px;
}
.Checkbox__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: var(--back-hover-color);
}
.Checkbox__container.Checkbox__container_checked {
  background-color: var(--btn-del-grey-color);
}
