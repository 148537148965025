.BtnIgnore {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background:  url('../../../../assets/ignore-icon.svg') no-repeat 50% 50%/ 16px 16px var(--item-back-color);
    cursor: pointer;
}
.BtnIgnore.BtnIgnore_transparent-back {
    background:  url('../../../../assets/ignore-icon.svg') no-repeat 50% 50%/ 16px 16px transparent;
}
.BtnIgnore:hover,
.BtnIgnore.BtnIgnore_isIgnored {
    background:  url('../../../../assets/ignore-icon-hover.svg') no-repeat 50% 50%/ 16px 16px var(--card-label-color);
}
.BtnIgnore.BtnIgnore_24 {
    height: 24px;
    width: 24px;
    border-radius: 2px;
}
.BtnIgnore:disabled,
.BtnIgnore:disabled:hover {
    opacity: .5;
    cursor: unset;
    background:  url('../../../../assets/ignore-icon.svg') no-repeat 50% 50%/ 16px 16px var(--item-back-color);
}
.BtnIgnore.BtnIgnore_isIgnored:disabled,
.BtnIgnore.BtnIgnore_isIgnored:disabled:hover {
    background:  url('../../../../assets/ignore-icon-hover.svg') no-repeat 50% 50%/ 16px 16px var(--card-label-color);
}