.NHeading3 {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-text-color);
}
a:hover .NHeading3 {
  color: var(--main-accent-color);
}
.NHeading3.NHeading3_link-color {
  color: var(--main-accent-color);
  cursor: pointer;
}
.NHeading3.NHeading3_link-color:hover {
  text-decoration: underline;
}
.NHeading3.NHeading3_error {
  color: var(--error-color);
}
.NHeading3.NHeading3_lh16 {
  line-height: 16px;
}
.NHeading3.NHeading3_lh40 {
  line-height: 40px;
}
.NHeading3.NHeading3_lh50 {
  line-height: 50px;
}