body {
  --main-font-family: 'Ubuntu', sans-serif;
  --heading-font-family: 'Ubuntu', sans-serif;
  --icon-font-family: 'Lato', sans-serif;
  --logo-font-family: 'Montserrat', sans-serif;

  --body-back-color: #F2F2F2;
  --main-text-color: #45455A;
  --main-text-color-alpha: rgba(69, 69, 90, 0.5);
  --dark-text-color: #2F3033;
  --sec-text-color: #9B9B9B;
  --sec-text-soft-color: #AAB7C5;
  --label-color: #6D6D6D;
  --sidebar-border-color: #DDE1E6;
  --card-label-color: #6F89A7;
  --form-field-border-color: #C7CCD1;
  --main-accent-color: #3537E5;
  --btn-accent-color: #FFFFFF;
  --btn-disabled-color: var(--main-text-color-alpha);
  --btn-accent-hover-color: #1C1EB9;
  --item-back-color: #F6F7FB;
  --item-back-hover-color: #E1E3F8;
  --warning-color: #E73761;
  --error-color: #FF556A;
  --header-border-color: #DDE1E6;
  --black-color: #000000;
  --status-color: #7ED321;
  --btn-back-color: #EFF3F7;
  --tag-btn-hover-color: #EBEBFD;
  --tag-label-color: #eaebfc;
  --tag-label-del-ico-hover-color: #d8e2fa;
  /*--prepared-color: #2F905C;*/
  --prepared-color: #5EB500;
  --unloaded-color: #CCAB00;
  --rule-color: #b7c4d3;
  --rule-active-color: #417505;
  --back-hover-color: #F5F5FD;
  --doc-gray-color: #B3BFCD;
  --doc-text-color: #505060;
  --disabled-breadcrumb-color: #c0cbd9;
  --alert-back-error-color: rgba(255, 122, 122, 0.1);
  --alert-back-success-color: rgba(68, 186, 0, 0.1);
  --alert-back-warning-color: rgba(255, 207, 84, 0.1);
  --alert-back-notification-color: rgba(111, 137, 167, 0.1);
  --alert-error-color: #FF7A7A;
  --alert-success-color: #44BA00;
  --alert-warning-color: #FFCF54;
  --alert-report-warning-color: #e58d35;
  --alert-notification-color: #6F89A7;
  --history-updated-color: #DB8520;
  --history-deleted-color: #D51313;
  --filter-select-back-color: #ECEDF9;
  --btn-del-grey-color: #e2e2fb;
  --menu-item-back-hover-color: #1F21A4;
  --breadcrumb-color: #C1C1CD;
  --list-item-inner-border-color: #E3E4F9;
  --btn-del-hover-color: #FF005C;
  --report-contract-link-color: #8D9EB3;
  --doc-back-hover-color: #F3F3FE;
  --folder-back-hover-color: #FCFCFF;
  --section-border-color: #D9E5F4;
  --empty-box-back-color: rgba(226, 226, 251, 0.2);
  --info-panel-label-color: #5B6571;
  --modal-delete-btn-color: #FCEBEC;
  --modal-delete-btn-label-color: #E63946;
  --new-back-color: #f5f9fa;
  --btn-new-item-grey-color: rgba(69, 69, 90, 0.1);
  --bg-secondary: #E2E9FB;

  /*#ffdde1*/


  --border-radius: 4px;
  --filter-select-border-radius: 4px 0 0 4px;
  --filter-select-btn-border-radius: 0 4px 4px 0;
  --menu-item-border-radius: 5px;
  --card-border-radius: 3px;

  --el-height: 40px;
  --card-btn-height: 64px;
  --full-card-select-height: 56px;
  --header-height: 56px;
  --subheader-height: 65px;
  --subheader-left-width: 126px;
  --subheader-left-margin: 16px;
  --content-width: 832px;
  --wide-content-width: 1124px;
  --sidebar-width: 128px;
  --sidebar-small-width: 64px;
  --sidebar-right-margin: calc(3*var(--distance-unit));
  --quarter-card-width: 178px;
  --half-card-width: 380px;
  --three-quarter-card-width: calc(var(--quarter-card-width) + 3*var(--distance-unit) + var(--half-card-width));

  --distance-unit: 8px;

  --main-transition-time: .3s;

  --select-box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  0 100px 80px rgba(0, 0, 0, 0.07);

}

