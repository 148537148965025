.SearchRequestLoadingIcon {
    width: 16px;
    height: 16px;
    background: url("./../../../../assets/search-request-loading-icon.png") no-repeat center / contain;
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotation {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(90deg);}
    50% {transform: rotate(180deg);}
    75% {transform: rotate(270deg);}
    100% {transform: rotate(360deg);}
}
