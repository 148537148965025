.NHeading1 {
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: var(--main-text-color);
}
.NHeading1.NHeading1_lh24 {
  line-height: 24px;
}
.NHeading1.NHeading1_lh28 {
  line-height: 28px;
}
.NHeading1.NHeading1_lh32 {
  line-height: 32px;
}
.NHeading1.NHeading1_lh40 {
  line-height: 40px;
}