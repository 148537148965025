.TileIcon {
  /*background: var(--item-back-color);*/
  border-radius: var(--card-border-radius);
  padding: var(--distance-unit) calc(2*var(--distance-unit));
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--black-color);
  display: flex;
  width: fit-content;
  min-width: 70px;
}
.TileIcon__container {
  margin-right: var(--distance-unit);
}
.TileIcon__finterms-ico {
  width: 16px;
  background: url("./../../../../assets/v4-finterms-tile-ico.svg") no-repeat center / contain;
}
.TileIcon__documents-ico {
  width: 16px;
  background: url("./../../../../assets/v4-documents-tile-ico.svg") no-repeat center / contain;
}
.TileIcon__templates-ico {
  width: 16px;
  background: url("./../../../../assets/v4-templates-tile-ico.svg") no-repeat center / contain;
}
.TileIcon__contracts-ico {
  width: 16px;
  background: url("./../../../../assets/v4-contracts-tile-ico.svg") no-repeat center / contain;
}
.TileIcon__organizations-ico {
  width: 16px;
  background: url("./../../../../assets/v4-organizations-tile-ico.svg") no-repeat center / contain;
}
.TileIcon__agents-ico {
  width: 16px;
  background: url("./../../../../assets/v4-agents-tile-ico.svg") no-repeat center / contain;
}
.TileIcon.TileIcon_disabled {
  opacity: .5;
}