.ui-kit__menu-heading-link-item {
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}
.ui-kit__menu-heading-link-item:first-child {
  margin-top: 0;
}
.ui-kit__menu-link-item {
  display: block;
  padding-left: 32px;
  margin-bottom: 8px;
}
.ui-kit__menu-group.ui-kit__menu-group_hide {
  display: none;
}
.UiKit {
  margin-top: calc(  -1 * (var(--header-height) + 6*var(--distance-unit) ) );
}
