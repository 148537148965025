.Heading5 {
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--main-text-color);
}
.Heading5.Heading5_link-color {
  color: var(--main-accent-color);
}
.Heading5:hover {
  text-decoration: underline;
}
.Heading5.Heading5_no-underline:hover {
  text-decoration: none;
}
.Heading5.Heading5_lh22 {
  line-height: 22px;
}
.Heading5.Heading5_lh24 {
  line-height: 24px;
}