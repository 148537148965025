.Layout {
  min-height: 100vh;
  background-color: var(--new-back-color);
}
.LeftSideBar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--sidebar-width);
  border-right: 1px solid var(--sidebar-border-color);
  background-color: #FFFFFF;
  z-index: 2500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width .268s ease-out;
}
.Content {
  /*max-width: calc(var(--sidebar-width) + var(--sidebar-right-margin) + var(--content-width));*/
  max-width: var(--wide-content-width);
  margin-left: calc(var(--sidebar-width) + var(--sidebar-right-margin));
  padding-top: calc(var(--header-height) + 3*var(--distance-unit));
  padding-bottom: calc(var(--header-height) + 6*var(--distance-unit));
  transition: margin .268s ease-out;
  position: relative;
}
.LefSideBar__content-container {
  transition: width .268s ease-out;
  width: calc(var(--sidebar-width) - 1px );
  /*height: calc(100vh - 12*var(--distance-unit) - var(--header-height));*/
  overflow: auto;
}
.LeftSideBar__top {
  height: var(--header-height);
}
/* Collapsed menu */
.Layout__Compact .LeftSideBar{
  width: var(--sidebar-small-width);
  z-index: 3001;
}
.Layout__Compact .LefSideBar__content-container {
  width: calc(var(--sidebar-small-width) - 1px );
  overflow: visible;
}
.Layout__Compact .MainMenuItemContainer {
  display: flex;
  align-items: center;
  position: relative;
}
.Layout__Compact .MainMenuItemContainer .MainMenuItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Layout__Compact .MainMenuItem .MainMenuItem__linkText {
  position: absolute;
  left: -400px;
  margin-left: 0;
  padding: 12px 16px;
  background-color: var(--main-accent-color);
  border-radius: 2px;
  opacity: 0;
  font-weight: 500;
  transition: opacity .268s ease-out;
}
.Layout__Compact .MainMenuItem.MainMenuItem_active:hover .MainMenuItem__linkText,
.Layout__Compact .MainMenuItem:hover .MainMenuItem__linkText {
  margin-left: 4px;
  left: var(--sidebar-small-width);
  color: var(--btn-accent-color);
  opacity: 1;
}
.LefSideBar__toggle_view {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}
.LefSideBar__toggle_view:hover {
  background-color: var(--back-hover-color);
}
.LefSideBar__toggle_view::after {
  opacity: .5;
  width: 24px;
  height: 24px;
  content: url("./../../../assets/filter-select-icon.svg");
  transform: rotate(90deg);
  transition: transform .268s ease-out;
}
.Layout__Compact .LefSideBar__toggle_view::after {
  transform: rotate(270deg);
}
.Layout__Compact .Content {
  margin-left: calc(var(--sidebar-small-width) + var(--sidebar-right-margin));
}
.Layout__Compact .MainMenuItem__icon-container {
  height: 20px;
  width: 20px;
  margin: 0;
}
.Layout__Compact .MainMenuItem {
  padding: 0;
  height: 40px;
}
