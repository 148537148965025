.RowMenu {
    background-color: var(--new-back-color);
    padding-bottom: var(--distance-unit);
    width: 100%;
}
.RowMenu__inner-container {
    padding-top: var(--distance-unit);
    padding-bottom: var(--distance-unit);
    display: flex;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid var(--header-border-color);
}
.RowMenu__options {
    display: flex;
    width: calc(100% - 32px - 24px);
    max-width: calc(100% - 32px - 24px);
    align-items: center;
}
.RowMenuItem {
    padding: 0 calc(2*var(--distance-unit));
    height: 32px;
    line-height: 32px;
    position: relative;
    color: var(--main-text-color);
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
}
.RowMenuItem__dash {
    display: none;
    top: calc(30px + var(--distance-unit));
    position: absolute;
    height: 2px;
    background-color: var(--main-accent-color);
    left: 4px;
    right: 4px;
}
.RowMenuItem.RowMenuItem_active {
    color: var(--main-accent-color);
}
.RowMenuItem.RowMenuItem_active .RowMenuItem__dash{
    display: block;
}
.RowMenuItem:hover {
    color: var(--main-accent-color);
}
.RowMenuOverflowBtn {
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.RowMenuOverflowBtn__dots {
    width: 16px;
    height: 16px;
    background: url("./../../../../assets/row-menu-overflow-btn-icon.svg") no-repeat center / contain;
}
.RowMenuOverflowBtn:hover {
    background-color: var(--header-border-color);
}
.RowMenuOverflowBtn.RowMenuOverflowBtn_active {
    background-color: var(--main-accent-color);
}
.RowMenuOverflowBtn.RowMenuOverflowBtn_active .RowMenuOverflowBtn__dots {
    background: url("./../../../../assets/row-menu-overflow-btn-active-icon.svg") no-repeat center / contain;
}
.RowMenuOverflow {
    position: relative;
}
.RowMenuOverflow__options {
    position: absolute;
    padding: var(--distance-unit) 0;
    background-color: var(--btn-accent-color);
    box-shadow: var(--select-box-shadow);
    z-index: 20;
    right: 0;
    top: 44px;
    display: none;
}
.RowMenuOverflow.RowMenuOverflow_active .RowMenuOverflow__options {
    display: block;
}
.RowMenuOverflow__option {
    height: 38px;
    line-height: 38px;
    padding: 0 calc(3*var(--distance-unit));
    color: var(--main-text-color);
    font-family: var(--main-font-family), serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
}
.RowMenuOverflow__option:hover {
    color: var(--main-accent-color);
    background-color: var(--header-border-color);
}
.RowMenuOverflow__option.RowMenuOverflow__option_active {
    color: var(--main-accent-color);
}