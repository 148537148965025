.DocumentFolderItem{
    display:block;
    height:170px;
    width:132px;
    overflow: hidden;

}
.DocumentFolderItem:hover{
    background-color: var(--folder-back-hover-color);
    border-radius: 10px;
    cursor: pointer;
}
.DocumentFolderItem:hover .button__icon.button__icon_hover {
    opacity: 1;
}
.DocumentFolderItem__iconContainer {
    width: 100px;
    height: 73px;
    position: relative;
    margin-bottom: var(--distance-unit);
}
.NCard .DocumentFolderItem__iconContainer .button__icon {
    max-width: 100%;
    max-height: 100%;
}
.DocumentFolderItem__docnumber{
    background: url(../../../../assets/document.png) no-repeat left center;
    font-weight: bold;
    font-size: 12px;
    height:14px;
    line-height: 14px;
    color: var(--doc-gray-color);
}
.DocumentFolderItem:hover .DocumentFolderItem__docnumber{
    background: url(../../../../assets/document-active.png) no-repeat left center;
    color:var(--main-accent-color);
    font-family: var(--main-font-family);
}
.DocumentFolderItem__docname{
    color: var(--doc-text-color);
    font-family: var(--main-font-family);
    font-size: 12px;
    line-height: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    width:100%;
    white-space: normal;
}
.DocumentFolderItem:hover .DocumentFolderItem__docname{
    color:var(--main-accent-color);
    text-decoration: underline;
}
.DocumentFolderItem__newFolder{
    cursor: pointer;
    width: 132px;
    height: 170px;
    position: relative;
}
.NCard .DocumentFolderItem__newFolder .button__icon {
    max-width: 100%;
    max-height: 100%;
}
.DocumentFolderItem__link-back{
    color: rgba(80, 80, 96, 0.3);
    display: inline-block;
    position:relative;
    padding-right:calc(4 * var(--distance-unit));
}
.DocumentFolderItem__link-back:after{
    content:'';
    position:absolute;
    top:50%;
    right:0;
    background: url(../../../../assets/arrow-back.png) no-repeat left center;
    transform: translateY(-50%);
    width:16px;
    height:16px;
}