.CopyBtnOnHover__container {
  position: relative;
  display: flex;
}
.CopyBtnOnHover__icon-container {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.CopyBtnOnHover {
  position: absolute;
  opacity: 0;
  width: 16px;
  height: 16px;
  cursor: unset;
}
.CopyBtnOnHover__checked-icon {
  position: absolute;
  opacity: 1;
  width: 16px;
  height: 16px;
  background: url("./../../../../assets/notification-on-icon.svg") no-repeat center / contain;
}
.CopyBtnOnHover__container:hover .CopyBtnOnHover {
  opacity: 1;
  cursor: pointer;
}
.CopyBtnOnHover__container:hover .CopyBtnOnHover__checked-icon {
  opacity: 0;
}