.Pre {
  display: block;
  line-height: 38px;
  padding: 0 16px;
  background-color: #d3d3d3;
  color: #000;
  font-size: 13px;
  font-family: monospace;
  margin: 0;
  text-overflow: ellipsis;
  /*overflow: hidden;*/
}
