.DropDownSelect {
  width: 218px;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  z-index: 100;
}
.DropDownSelect.DropDownSelect_focused {
  background-color: #FFFFFF;
  box-shadow: 0px 10px 40px -20px #0000009e;
}
.DropDownSelect__option {
  display: none;
  width: 100%;
  height: var(--el-height);
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--main-text-color);
  border-radius: 2px;
  cursor: pointer;
}
.DropDownSelect__option.DropDownSelect__option_active {
  display: block;
  cursor: pointer;
}
.DropDownSelect.DropDownSelect_focused .DropDownSelect__option.DropDownSelect__option_active {
  cursor: text;
}
.DropDownSelect__option:hover {
  color: var(--main-accent-color);
}
.DropDownSelect.DropDownSelect_focused .DropDownSelect__option {
  display: block;
}
.DropDownSelect.DropDownSelect_focused .DropDownSelect__option.DropDownSelect__option_active {
  background-color: var(--tag-label-color);
}
.DropDownSelect.DropDownSelect_focused .DropDownSelect__option.DropDownSelect__option_active:hover {
  color: var(--main-text-color);
}
.DropDownSelect__ico {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 14px;
  background: url('./../../../../assets/drop-down-select-ico.svg') center / 18px no-repeat;
  transform: rotate(180deg);
  transition: transform ease 1s;
  z-index: 20;
  cursor: pointer;
}
.DropDownSelect.DropDownSelect_focused .DropDownSelect__ico {
  transform: rotate(0deg);
}