.TextOverflowHandler {
  max-width: 95%;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TextOverflowHandler.TextOverflowHandler_mw-100 {
  max-width: 100%;
}
.TextOverflowHandler.TextOverflowHandler_wrap {
  white-space: normal;
}