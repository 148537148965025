.Filters {
  display: flex;
  flex-wrap: wrap;
}
/*.Filters.Filters_sticky {*/
/*  position: -webkit-sticky;*/
/*  position: sticky;*/
/*  z-index: 2000;*/
/*  top: var(--header-height);*/
/*  padding-top: 16px;*/
/*  background-color: var(--item-back-color);*/
/*  max-width: var(--wide-content-width);*/
/*  width: 100%;*/
/*}*/
.Filters.Filters_sticky {
  z-index: 2000;
  top: calc(var(--header-height) + var(--subheader-height) + 57px);
  /*background-color: var(--btn-accent-color);*/
  padding-top: var(--distance-unit);
  background-color: var(--new-back-color);
  max-width: var(--wide-content-width);
  width: 100%;
}
.w_220 {
  width: 220px;
}
.SelectFilterPlaceholder {
  display: flex;
  color: var(--main-text-color);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.SelectFilterPlaceholder__icon {
  width: 16px;
  height: 16px;
  background: url("./../../../assets/select-filter-placeholder-icon.svg") center / 16px 16px no-repeat;
}
.FilterSelect:hover .SelectFilterPlaceholder {
  color: var(--main-accent-color);
}
.FilterSelect:hover .SelectFilterPlaceholder__icon {
  background: url("./../../../assets/select-filter-placeholder-hover-icon.svg") center / 16px 16px no-repeat;
}
.FilterSelect:hover b .report-status_DRAFT {
  color: var(--btn-accent-color);
}