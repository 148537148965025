.AdaptiveFormContainer_twoHalfCardWidthFieldsModal,
.AdaptiveFormContainer_twoHalfCardWidthFields {
    display: flex;
    justify-content: space-between;
}
.AdaptiveFormContainer_twoHalfCardWidthFieldsModal__field-container,
.AdaptiveFormContainer_twoHalfCardWidthFields__field-container {
    width: var(--half-card-width);
    max-width: var(--half-card-width);
}
.AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal {
    display: flex;
}
.AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container:first-child,
.AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container:first-child {
    margin-right: calc(3*var(--distance-unit));
}
.AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container {
    width: var(--half-card-width);
    max-width: var(--half-card-width);
}

.AdaptiveFormContainer_twoHalfCardWidthFields1100 {
    display: flex;
    justify-content: space-between;
}
.AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal {
    display: flex;
}
.AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container,
.AdaptiveFormContainer_twoHalfCardWidthFields1100__field-container {
    width: var(--half-card-width);
    max-width: var(--half-card-width);
}

.AdaptiveFormContainer_twoQuarterCardWidthFields {
    display: flex;
    justify-content: space-between;
    width: var(--half-card-width);
    max-width: var(--half-card-width);
}
.AdaptiveFormContainer_twoQuarterCardWidthFields__field-container {
    width: var(--quarter-card-width);
    max-width: var(--quarter-card-width);
}

.AdaptiveFormContainer_threeQuarterCardWidth {
    display: flex;
    width: calc(3*var(--quarter-card-width) + 6*var(--distance-unit));
    max-width: calc(3*var(--quarter-card-width) + 6*var(--distance-unit));
    flex-wrap: wrap;
}
.AdaptiveFormContainer_threeQuarterCardWidth > div {
    margin-bottom: calc(3*var(--distance-unit));
    margin-right: calc(3*var(--distance-unit));
}
.AdaptiveFormContainer_threeQuarterCardWidth > div:last-child {
    margin-right: 0;
}

.AdaptiveFormContainer_threeQuarterCardWidthFields {
    display: flex;
    justify-content: space-between;
    width: var(--three-quarter-card-width);
    max-width: var(--three-quarter-card-width);
}
.AdaptiveFormContainer_threeQuarterCardWidthFields__field-container {
    width: var(--quarter-card-width);
    max-width: var(--quarter-card-width);
}
@media (max-width: 1150px) {
    .AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container,
    .AdaptiveFormContainer_twoHalfCardWidthFields__field-container {
        width: 48%;
        max-width: 48%;
    }
    .AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container,
    .AdaptiveFormContainer_twoHalfCardWidthFields1100__field-container {
        width: 48%;
        max-width: 48%;
    }
}
@media (max-width: 1100px) {
    .AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal,
    .AdaptiveFormContainer_twoHalfCardWidthFields1100 {
        display: block;
    }
    .AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container,
    .AdaptiveFormContainer_twoHalfCardWidthFields1100__field-container {
        width: 100%;
        max-width: 100%;
    }
    .AdaptiveFormContainer_twoHalfCardWidthFields1100JustifyContentNormal__field-container:first-child,
    .AdaptiveFormContainer_twoHalfCardWidthFields1100__field-container:first-child {
        margin-bottom: calc(3*var(--distance-unit));
    }
}
@media (max-width: 950px) {
    .AdaptiveFormContainer_threeQuarterCardWidth {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 850px) {
    .AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal,
    .AdaptiveFormContainer_twoHalfCardWidthFieldsModal,
    .AdaptiveFormContainer_twoHalfCardWidthFields {
        display: block;
    }
    .AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container,
    .AdaptiveFormContainer_twoHalfCardWidthFields__field-container {
        width: 100%;
        max-width: 100%;
    }
    .AdaptiveFormContainer_twoHalfCardWidthFieldsJustifyContentNormal__field-container:first-child,
    .AdaptiveFormContainer_twoHalfCardWidthFieldsModal__field-container:first-child,
    .AdaptiveFormContainer_twoHalfCardWidthFields__field-container:first-child {
        margin-bottom: calc(3*var(--distance-unit));
    }


}