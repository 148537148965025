.CheckListContainer {
  width: 100%;
  background-color: var(--btn-back-color);
  padding: var(--distance-unit) calc(3*var(--distance-unit));
  max-height: 374px;
  overflow-y: auto;
}
.CheckListContainer .CheckboxInput {
  border-bottom: 1px solid var(--header-border-color);
  display:block;
}
.CheckListContainer .CheckboxInput:last-child {
  border-bottom: unset;
}
.CheckListContainer .Checkbox{
  float:left;
}