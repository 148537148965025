.NHeading2 {
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--main-text-color);
}
.NHeading2.NHeading2_lh22 {
  line-height: 22px;
}
.NHeading2.NHeading2_lh40 {
  line-height: 40px;
}
.NHeading2.NHeading2_grey {
  color: var(--alert-notification-color);
}
.NHeading2.NHeading2__span-link-color {
  display: inline;
  color: var(--main-accent-color);
}