.WrappedDateRangePicker {

}
.WrappedDateRangePicker.WrappedDateRangePicker_shiftedRightForUiKit {
    position: relative;
    left: 300px;
}
.FilterWrappedDateRangePicker {
    display: flex;
}
.FilterWrappedDateRangePicker .filter-dateRange-propNameLabel,
.FilterWrappedDateRangePicker .WrappedDateRangePicker__dateIconContainer {
    cursor: pointer;
}
.WrappedDateRangePicker__wrappedInputContainer {
    display: flex;
}
.FilterWrappedDateRangePicker .WrappedDateRangePicker__wrappedInputContainer{
    background-color: var(--filter-select-back-color);
    /*background-color: var(--item-back-color);*/
    border-radius: var(--filter-select-btn-border-radius);
}
.WrappedDateRangePicker__inputSeparator {
    display: flex;
    align-items: center;
}
.WrappedDateRangePicker__dateIconContainer {
    display: flex;
    align-items: center;
    padding: 0 calc(2*var(--distance-unit)) 0 0;
}

.WrappedDateRangePicker__dropdown {
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.03), 0px 3px 3px rgba(0, 0, 0, 0.02), 0px 7px 6px rgba(0, 0, 0, 0.03), 0px 12px 10px rgba(0, 0, 3, 0.03), 0px 22px 18px rgba(0, 0, 0, 0.04), 0px 40px 33px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    padding: calc(3 * var(--distance-unit));
    position: absolute;
    z-index: 20;
    background-color: #ffffff;
    left: 0;
}
.WrappedDateRangePicker__dropdown .react-datepicker__view-calendar-icon input{
    width:175px;
}
.WrappedDateRangePicker__dropdown.WrappedDateRangePicker__dropdown_isOnlyModalMode {
    box-shadow: unset;
    position: static;
}
.WrappedDateRangePicker__section-border {
    width: 100%;
    border-bottom: 1px solid var(--section-border-color);
}
.WrappedMonthPicker__NSelect {
    width: 146px;
}
.WrappedMonthPicker {
    width: 100%;
}
