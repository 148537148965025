.EmptyBox {
    background-color: var(--empty-box-back-color);
    border-radius: var(--border-radius);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.EmptyBox__text {
    font-family: var(--main-font-family);
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: var(--card-label-color);
    text-transform: uppercase;
}