.CustomDropDownSelect {
    position: relative;
}
.CustomDropDownSelect__header-container {
    
}
.CustomDropDownSelect__options-relative-container {
    position: relative;
    display: none;
    z-index: 20;
}
.CustomDropDownSelect__options-container {
    position: absolute;
    width: 160px;
    background-color: var(--btn-accent-color);
}
.CustomDropDownSelect.CustomDropDownSelect_focused .CustomDropDownSelect__options-relative-container {
    display: block;
}
.CustomDropDownSelect__option {
    width: 100%;
    height: var(--el-height);
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--main-text-color);
    cursor: pointer;
}
.CustomDropDownSelect__option:hover {
    background-color: var(--btn-del-grey-color);
    color: var(--menu-item-back-hover-color);
}
.CustomDropDownSelect__option.CustomDropDownSelect__option_active {
    background-color: var(--menu-item-back-hover-color);
    color: var(--btn-accent-color);
    cursor: text;
}