.NDropDownDetails__summary-container {
  width: 100%;
  border-radius: var(--card-border-radius);
  background-color: var(--item-back-color);
  padding: calc(1.5*var(--distance-unit)) calc(3*var(--distance-unit));
  color: var(--main-text-color);
  font-family: var(--main-font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  z-index: 200;
}
.DropDownTitle_optionsAreShown .NDropDownDetails__summary-container {
  color: var(--main-accent-color);
}
.NDropDownDetails__detailsOptions {
  /*transition: all 1s ease;*/
  /*transform: translateY(-4px);*/
  /*margin-top: 4px;*/
}
.DropDownOptions_shown .NDropDownDetails__detailsOptions{
  /*transform: translateY(0);*/
}
.NDropDownDetails__ico {
  position: absolute;
  top: 12px;
  right: 19px;
  bottom: 16px;
  width: 16px;
  height: 16px;
  background: url("./../../../../assets/select-arrow.svg") no-repeat center;
  transition: transform 1s ease;
}
.DropDownTitle_optionsAreShown .NDropDownDetails__summary-container .NDropDownDetails__ico {
  background: url("./../../../../assets/select-arrow-active.svg") no-repeat center;
  transform: rotate(180deg);
}
/*.DataListContainer__item .DataListContainer,*/
/*.DataListContainer__item .NDropDownDetails__summary-container {*/
/*  background-color: var(--item-back-hover-color);*/
/*}*/
.NDropDownDetails__summary-container .LoadingIcon {
  position: absolute;
  top: 0;
  bottom: unset;
  right: 0;
}