.ActTemplate__text {
  color: var(--main-text-color);
  font-family: var(--main-font-family);
  font-size: 14px;
  line-height: 19px;
  /*margin-bottom: calc(3*var(--distance-unit));*/
}
.FinancialConditionItem__file-ico {
  display: block;
}
.upload-doc__btn-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.ButtonNewItem__arrow {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  vertical-align: middle;
}