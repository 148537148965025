/*.NCard.ListItemCard {*/
/*    padding: 0;*/
/*    display: grid;*/
/*    grid-template-columns: auto 257px;*/
/*    overflow: hidden;*/
/*}*/
.ListItemCard {
    display: flex;
}
.ListItemCard.ListItemCard_wide {
    width: 100%;
    max-width: var(--wide-content-width);
}
.ListItemCard__left {
    border-right: 1px solid var(--list-item-inner-border-color);
    width: 60%;
    min-width: 60%;
    /*padding: calc(3*var(--distance-unit)) 0 calc(3*var(--distance-unit)) calc(3*var(--distance-unit));*/
}
.ListItemCard__above-title {
    display: flex;
    justify-content: space-between;
}
.ListItemCard__above-title-right {
    text-align: right;
}
.ListItemCard__right {
    /*border-left: 1px solid var(--item-back-color);*/
    /*padding: calc(3*var(--distance-unit)) calc(3*var(--distance-unit)) calc(8*var(--distance-unit));*/
    /*position: relative;*/
    display: flex;
    width: 40%;
    min-width: 40%;
}
.ListItemCard__left-1st-row {
    /*padding-bottom: calc(2*var(--distance-unit));*/
    padding: calc(3*var(--distance-unit)) 0 calc(2*var(--distance-unit)) calc(3*var(--distance-unit));
    border-bottom: 1px solid var(--item-back-color);
}
.ListItemCard__left-2nd-row {
    padding-top: calc(2*var(--distance-unit));
    padding-bottom: calc(2*var(--distance-unit));
    padding-left: calc(3*var(--distance-unit));
}
.ListItemCard__left-3rd-row {
    /*margin-top: calc(2*var(--distance-unit));*/
    padding-top: calc(2*var(--distance-unit));
    padding-bottom: calc(2*var(--distance-unit));
    padding-left: calc(3*var(--distance-unit));
    border-top: 1px solid var(--item-back-color);
}
.ListItemCard__tags {
    /*margin-top: calc(2*var(--distance-unit));*/
    /*padding-left: calc(2*var(--distance-unit));*/
    /*padding-right: calc(2*var(--distance-unit));*/
    /*border-left: 1px solid var(--item-back-color);*/
    display: flex;
    flex-wrap: wrap;
    /*min-width: 316px;*/
    /*max-width: 571px;*/
}
.ListItemCard__tags .TagLabel {
    margin-top: calc(2*var(--distance-unit));
    margin-right: var(--distance-unit);
}
.ListItemCard__related-items {
    display: flex;
    padding: calc(3*var(--distance-unit));
    border-right: 1px solid var(--list-item-inner-border-color);
    width: calc(100% - 104px);
    min-width: calc(100% - 104px);
}
.ListItemCard__related-items .SecText {
    margin-right: calc(4*var(--distance-unit));
}
.ListItemCard__related-items-list {
    max-width: 180px;
}
/*.ListItemCard__tiles .TileIcon {*/
/*    margin-right: 6px;*/
/*}*/
.ListItemCard__tags .TagLabel:last-child
/*.ListItemCard__tiles .TileIcon:last-child */
{
    margin-right: 0;
}
.ListItemCard__tiles {
    min-width: 112px;
    padding: calc(3*var(--distance-unit)) calc(2*var(--distance-unit));
    display: flex;
    align-items: center;
    /*position: absolute;*/
    /*left: calc(3*var(--distance-unit));*/
    /*right: calc(3*var(--distance-unit));*/
    /*bottom: calc(3*var(--distance-unit));*/
    /*display: flex;*/
}
.ListItemCard__tiles .ButtonTooltipContainer {
    border-bottom: 1px solid var(--item-back-color);
}
.ListItemCard__tiles .ButtonTooltipContainer:last-child {
    border-bottom: unset;
}
.ListItemCard__buttons {
    width: 64px;
    border-left: 1px solid var(--list-item-inner-border-color);
}


.ListItemCard.ListItemCard_wide.UserCard .ListItemCard__left,
.ListItemCard.ListItemCard_wide.MyOrganizationCard .ListItemCard__left {
    width: calc(100% - 112px);
    min-width: calc(100% - 112px);
}

.ListItemCard.ListItemCard_wide.UserCard .ListItemCard__right,
.ListItemCard.ListItemCard_wide.MyOrganizationCard .ListItemCard__right {
    width: 112px;
    min-width: 112px;
}
.ListItemCard.ListItemCard_wide.AgentCard .ListItemCard__left,
.ListItemCard.ListItemCard_isLinkedItem .ListItemCard__left,
.ListItemCard.ListItemCard_isLinkedItem.AgentCard .ListItemCard__left {
    width: calc(100% - 176px);
    min-width: calc(100% - 176px);
}
.ListItemCard.ListItemCard_wide.AgentCard .ListItemCard__right,
.ListItemCard.ListItemCard_isLinkedItem .ListItemCard__right,
.ListItemCard.ListItemCard_isLinkedItem.AgentCard .ListItemCard__right {
    width: 176px;
    min-width: 176px;
}


@media (max-width: 1460px) {
    .ListItemCard__related-items {
        display: block;
    }
    .ListItemCard__related-items-list {
        max-width: 100%;
    }
}
@media (max-width: 980px) {
    .ListItemCard {
        display: block;
    }
    .ListItemCard.AgentCard,
    .ListItemCard.UserCard,
    .ListItemCard.MyOrganizationCard,
    .ListItemCard.ListItemCard_isLinkedItem {
        display: flex;
    }
    .ListItemCard__left {
        border-right: unset;
        border-bottom: 1px solid var(--list-item-inner-border-color);
        width: 100%;
        min-width: 100%;
    }
    .ListItemCard.ListItemCard_isLinkedItem .ListItemCard__left {
        border-right: 1px solid var(--list-item-inner-border-color);
        border-bottom: unset;
        width: calc(100% - 176px);
        min-width: calc(100% - 176px);
    }
    .ListItemCard.ListItemCard_wide.UserCard .ListItemCard__left,
    .ListItemCard.ListItemCard_wide.MyOrganizationCard .ListItemCard__left {
        border-right: 1px solid var(--list-item-inner-border-color);
        border-bottom: unset;
        width: calc(100% - 112px);
        min-width: calc(100% - 112px);
    }
    .ListItemCard.ListItemCard_wide.AgentCard .ListItemCard__left {
        border-right: 1px solid var(--list-item-inner-border-color);
        border-bottom: unset;
    }
    .ListItemCard__right {
        width: 100%;
        min-width: 100%;
    }
    .ListItemCard__related-items-list {
        max-width: 100%;
    }
    .ListItemCard__above-title {
        display: block;
    }
    .ListItemCard__above-title-right {
        text-align: left;
    }
}