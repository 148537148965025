.SmallTextBtn {
  display: block;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: uppercase;
  opacity: .8;
  cursor: pointer;
}
.SmallTextBtn.SmallTextBtn_reset {
  color: var(--error-color);
}
.SmallTextBtn.SmallTextBtn_clean {
  color: var(--card-label-color);
}
.SmallTextBtn:hover {
  opacity: 1;
}
.SmallTextBtn.SmallTextBtn_lh40 {
  line-height: 40px;
}
.SmallTextBtn--text_transform_none {
  text-transform: none;
}