.BtnUpload {
  background: url('../../../../assets/upload.png') no-repeat calc(2 * var(--distance-unit)) center var(--main-accent-color);
  border-radius: var(--border-radius);
  height: var(--el-height);
  font-family: var(--heading-font-family);
  color: var(--btn-accent-color);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: var(--el-height);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.upload-doc__btn-container:hover .BtnUpload,
.BtnUpload:hover {
  background-color: var(--btn-accent-hover-color);
}
.BtnUpload.BtnUpload_inverse {
  border: 1px solid var(--main-accent-color);
  background-color: transparent;
  color: var(--main-accent-color);
}
.BtnUpload.BtnUpload_inverse:hover {
  background-color: var(--main-accent-color);
  color: var(--btn-accent-color);
}
.BtnUpload:disabled {
  opacity: .5;
  cursor: unset;
}
.BtnUpload:disabled:hover {
  background-color: var(--main-accent-color);
}
.BtnUpload.BtnNewItem_inverse.BtnNewItem_disabled {
  opacity: .5;
  cursor: unset;
}
.BtnUpload.BtnNewItem_inverse:disabled:hover,
.BtnUpload.BtnNewItem_inverse.BtnNewItem_disabled:hover {
  background-color: transparent;
  color: var(--main-accent-color);
  opacity: .5;
  cursor: unset;
}
.BtnUpload.BtnNewItem_full-width {
  width: 100%;
}