.NSwitcher {
  display: flex;
  flex-wrap: wrap;
}
.NSwitcher__tab {
  height: var(--el-height);
  line-height: var(--el-height);
  padding-left: calc(2*var(--distance-unit));
  padding-right: calc(2*var(--distance-unit));
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: var(--black-color);
  cursor: pointer;
  background-color: var(--item-back-color);
  margin-right: var(--distance-unit);
  border-radius: var(--menu-item-border-radius);
}
.NSwitcher__tab.NSwitcher__tab_active {
  background-color: var(--main-accent-color);
  color: var(--btn-accent-color);
  cursor: unset;
}
.NSwitcher__tab:hover {
  color: var(--main-accent-color);
}
.NSwitcher__tab.NSwitcher__tab_active:hover {
  color: var(--btn-accent-color);
}
.NSwitcher__tab:last-child {
  margin-right: 0;
}

.NSwitcher.NSwitcher_style-v2 {
  flex-wrap: nowrap;
}
.NSwitcher.NSwitcher_style-v2 .NSwitcher__tab {
  white-space: nowrap;
  margin-right: 0;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: transparent;
}
.NSwitcher.NSwitcher_style-v2 .NSwitcher__tab.NSwitcher__tab_active {
  background-color: var(--tag-btn-hover-color);
  color: var(--main-accent-color);
  cursor: unset;
}
@media (max-width: 850px) {
  .NSwitcher__tab {
    margin-bottom: 4px;
  }
}