.CheckboxSelect {
    background-color: var(--btn-accent-color);
    padding: var(--distance-unit) calc(2*var(--distance-unit));
}
.CheckboxSelect.CheckboxSelect_sm .CheckboxInput {
    padding: calc(1*var(--distance-unit)) 0;
    align-items: center;
}
.CheckboxSelect.CheckboxSelect_sm .CheckboxInput__label {
    white-space: nowrap;
}
.CheckboxSelect.CheckboxSelect_sm .CheckboxInput .Checkbox {
    margin-right: calc(1*var(--distance-unit));
    height: 12px;
    width: 12px;
}