.dynamic-param-row {
    display: flex;
    flex-wrap: wrap;
}
/*.dynamic-param-row__1st-col {*/
/*    width: 260px;*/
/*    max-width: 260px;*/
/*}*/
/*.dynamic-param-row__2nd-col {*/
/*    width: 240px;*/
/*    max-width: 240px;*/
/*}*/
/*.dynamic-param-row__3rd-col {*/
/*    width: 230px;*/
/*    max-width: 230px;*/
/*}*/
.GroupFinTermTemplateParamsRow {
    display: flex;
}
.GroupFinTermTemplateParamRowWithBtn {
    display: flex;
    width: 100%;
}
.GroupFinTermTemplateParamRowWithBtn .NFormGroup .DataListContainer {
    width: calc(100% - 40px - var(--distance-unit)/2);
}
.dynamic-param-row__1st-col {
    min-width: 35%;
    width: 35%;
    max-width: 35%;
}
.dynamic-param-row__2nd-col {
    min-width: 33%;
    width: 33%;
    max-width: 33%;
}
.dynamic-param-row__3rd-col {
    min-width: 32%;
    width: 32%;
    max-width: 32%;
}
.dynamic-param-row__3rd-col_shorten {
    min-width: calc(32% - 20px);
    width: calc(32% - 20px);
    max-width: calc(32% - 20px);
}
.ConvertedTag {
    padding-top: var(--distance-unit);
    border-top: 1px dashed var(--form-field-border-color);
}
/*.GroupFinTermTemplateParamRowWithBtn .dynamic-param-row__3rd-col {*/
/*    width: 192px;*/
/*    max-width: 192px;*/
/*}*/
/*.GroupFinTermTemplateParamRowWithBtn .dynamic-param-row__3rd-col_shorten {*/
/*    width: 172px;*/
/*    max-width: 172px;*/
/*}*/
.hookIdAbsPos {
    position: absolute;
    bottom: 200px;
}
.EditConvertedTag__rel-container {
    position: relative;
    min-width: 20px;
}
.EditConvertedTag__abs-container {
    position: absolute;
    background-color: #FFFFFF;
    padding: calc( (var(--distance-unit)/2) - 1px) 0 0 calc(var(--distance-unit)/2);
    right: calc(-3*var(--distance-unit));
    top: calc(-2*var(--distance-unit));
}

@media (max-width: 930px) {
    .GroupFinTermTemplateParamsRow {
        flex-wrap: wrap;
    }
    .dynamic-param-row__1st-col {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        margin-bottom: var(--distance-unit);
    }
    .dynamic-param-row__2nd-col {
        min-width: 50%;
        width: 50%;
        max-width: 50%;
    }
    .dynamic-param-row__3rd-col {
        min-width: 50%;
        width: 50%;
        max-width: 50%;
    }
    .dynamic-param-row__3rd-col_shorten {
        min-width: calc(50% - 20px);
        width: calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
    .GroupFinTermTemplateParamRowWithBtn .BtnDel.DeleteConvertedTagBtn,
    .GroupFinTermTemplateParamRowWithBtn .EditConvertedTagBtn,
    .GroupFinTermTemplateParamRowWithBtn .ConvertTagBtn {
        height: 76px;
    }
    .EditConvertedTag__abs-container {
        top: calc(-6*var(--distance-unit) + 4px);
        /*bottom: 0;*/
    }
}