.button__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}
.button__icon.button__icon_hover,
.button__icon.button__icon_active {
  opacity: 0;
}
.button__icon.button__icon_hover:hover {
  opacity: 1;
}
.NCard .button__icon {
  max-width: 24px;
  max-height: 24px;
}