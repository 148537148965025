.DropDownOptions {
  /*overflow: hidden;*/
  transition: all var(--main-transition-time) ease;
}
.DropDownTitle {
  display: flex;
  cursor: pointer;
}
.DropDownTitle > .NDropDownDetails__ico {
  position: static;
  margin-left: calc(2 * var(--distance-unit));
}
.DropDownTitle_optionsAreShown > .NDropDownDetails__ico {
  background: url("./../../../../assets/select-arrow-active.svg") no-repeat center;
  transform: rotate(180deg);
}