.SearchCard {
  border-radius: 8px;
  background-color: var(--back-hover-color);
  padding: calc(2*var(--distance-unit)) calc(3*var(--distance-unit));
}
.matched-part {
  color: var(--main-accent-color);
}
/*.SearchCard__tag {*/
/*  background: var(--btn-del-grey-color);*/
/*  border-radius: 8px;*/
/*  height: var(--el-height);*/
/*  line-height: var(--el-height);*/
/*  padding: 0 calc(2*var(--distance-unit));*/
/*}*/
/*.SearchCard__tag .Heading5 {*/
/*  line-height: var(--el-height);*/
/*}*/
.TagLabel.SearchCard__TagLabel {
  background: var(--btn-del-grey-color);
  cursor: auto;
}
.TagLabel.SearchCard__TagLabel:hover {
  color: var(--card-label-color);
}
