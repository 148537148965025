.login-form-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    align-content: center;
    background-image: url("./../../src/assets/login-screen-back-v1.4.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.test-login{
    background-color: #f7f7f7;
    width:300px;
    margin:50px auto;
    border:1px solid #ccc;
    border-radius: 6px;
    padding:0 40px 20px;
    text-align:center;
}
.login-form{
    display: block;
    width: 418px;
    margin: auto;
    background-color: #FFFFFF;
    /*box-shadow: 0 20px 40px 0 rgba(0,0,0,0.3);*/
    padding: 40px 60px 60px;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 40px 33px rgba(0, 0, 0, 0.05), 0px 22px 18px rgba(0, 0, 0, 0.04), 0px 12px 10px rgba(0, 0, 3, 0.03), 0px 7px 6px rgba(0, 0, 0, 0.03), 0px 3px 3px rgba(0, 0, 0, 0.02), 0px -1px 12px rgba(0, 0, 0, 0.03);
}
.login-form-container__logo {
    display: block;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.login-form-container__logo_blue {
    color: #3537E5;
}
.login-form__label {
    display: block;
    color: #6D6D6D;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 5px;
}
.login-form__input {
    display: block;
    height: 40px;
    width: 100%;
    border: 1px solid #C7CCD1;
    border-radius: 4px;
    margin-bottom: 24px;
    font-family: 'Montserrat', sans-serif;
    padding: 0 16px;
}
.login-form__input.login-form__input__pass {
    margin-bottom: 40px;
}
.login-form__input.login-form__input_error {
    border: 1px solid #ff0000;
}
.login-form__submit {
    display: block;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    color: #ffffff;
    background-color: #3537E5;
    text-align: center;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.39px;
    line-height: 18px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    opacity: .9;
}
.login-form__submit:hover {
    opacity: 1;
    box-shadow: 0 8px 10px -6px rgba(53,55,229,0.55);
}

.login-form__label.login-form__label_error {
    color: #e73761;
    text-align: center;
}
.login-form__label.login-form__label_error.login-form__label_error-hide {
    display: none;
}
