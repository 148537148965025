.NotificationSettingsForm {
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto auto;
    /*background-color: var(--btn-back-color);*/
    font-size: 14px;
    line-height: 1;
    overflow: hidden;
}

.NotificationSettingsForm__left {
}
.NotificationSettingsForm__right {
    display: grid;
}
.NotificationSettingsForm__header-item-container {
    height: 250px;
    width: 250px;
    border-left: 1px solid var(--header-border-color);
    /*border-bottom: 1px solid var(--header-border-color);*/
}
.NotificationSettingsForm__header-item {
    transform: rotate(-90deg) translate(-111px, -99px);
    white-space: nowrap;
    overflow: hidden;
    /*color: var(--card-label-color);*/
    font-size: 16px;
}
.NotificationSettingsForm__header-item-org {
    text-align: center;
    line-height: 250px;
    /*color: var(--card-label-color);*/
    /*border-bottom: 1px solid var(--header-border-color);*/
    font-size: 16px;
}
.NotificationSettingsForm__input-value {
    padding: var(--distance-unit) calc(var(--distance-unit) / 2);
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--header-border-color);
    border-left: 1px solid var(--header-border-color);
    line-height: 16px;
}
.NotificationSettingsForm__input-value.NotificationSettingsForm__input-value_view-mode {
    padding: calc(var(--distance-unit) / 2);
}
.NotificationSettingsForm__input-value.NotificationSettingsForm__input-value_view-mode img {
    min-height: 24px;
    display: block;
    height: 24px;
    max-height: 24px;
}
.NotificationSettingsOrgLabel {
    padding: calc(var(--distance-unit) / 2) var(--distance-unit);
    line-height: 24px;
    border-top: 1px solid var(--header-border-color);
    /*color: var(--card-label-color);*/
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.NotificationSettingsFormSingleOrg__row {
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid var(--header-border-color);
}
.NotificationSettingsFormSingleOrg_category {
    min-width: calc(100% - 50px);
    line-height: 32px;
}
.NotificationSettingsFormSingleOrg_input-container {
    min-width: 50px;
    border-left: 1px solid var(--header-border-color);
}
.NotificationSettingsFormSingleOrg_input-container .NotificationSettingsForm__input-value {
    border-left: unset;
    border-top: unset;
}

.NotificationSettingsFormSingleOrg__row:last-child {
    border-bottom: unset;
}
@media (max-width: 1050px) {
    .NotificationSettingsForm__header-item {
        transform: rotate(-90deg) translate(-111px, -113px);
    }
}