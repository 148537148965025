.CardBtn {
  width: 100%;
  height: var(--card-btn-height);
  text-align: center;
  border-radius: var(--border-radius);
  background-color: var(--btn-back-color);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  line-height: var(--card-btn-height);
  color: var(--main-accent-color);
  cursor: pointer;
}
.CardBtn:hover {
  background-color: var(--item-back-hover-color);
}