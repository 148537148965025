.Document__description-container {
  margin-bottom: 10px;
}
.doc__ico{
  position:relative;
  width:57px;
  height:64px;
  cursor: pointer;
}
.NCard .doc__ico .button__icon {
  max-width: 100%;
  max-height: 100%;
}
.Document:hover .button__icon.button__icon_hover {
  opacity: 1;
}
.doc__ico-content{
  position:absolute;
  bottom:3px;
  left:6px;
  font-size:12px;
  text-transform: uppercase;
  color:var(--main-accent-color);
  font-family: var(--icon-font-family);
  height:16px;
  line-height:16px;
  font-weight: 900;
}
.Document:hover .doc__ico-content{
  color: #ffffff;
}
.doc__size{
  width:50px;
  white-space: nowrap;
}



.upload-doc__btn-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.upload-doc__input {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 100;
}

.Document__name {
  line-height: 24px;
  max-width: 100%;
}
.Document__name:hover {
  color: var(--main-accent-color);
}
.Document {
  position: relative;
  padding: calc(2*var(--distance-unit));
  border-radius: 10px;
  display: flex;
}
.Document:hover {
  background-color: var(--doc-back-hover-color);
}
.Document .BtnEditAct {
  opacity: 0;
}
.Document:hover .BtnEditAct {
  opacity: 1;
}
.Document__isDeletedMessage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(256, 256, 256, .5);
  color: var(--alert-error-color);
}
.Document__left {
  display: flex;
  min-width: 60%;
  max-width: 60%;
}
.Document__right {
  min-width: 40%;
  max-width: 40%;
}
.Document__icon-container {
  margin-right: calc(3*var(--distance-unit));
}
.Document__name-and-data {
  min-width: calc(100% - 3*var(--distance-unit) - 57px);
}
.Document__size-and-index {
  display: flex;
}
.Document__date {
  text-align: right;
  margin-bottom: 16px;
}
.Document__linkToFolder {
  display: flex;
  opacity: 0;
}
.Document:hover .Document__linkToFolder {
  opacity: 1;
}
.Document__linkToFolderIco {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: url("./../../../../../assets/link-to-folder-icon.svg") center / contain no-repeat;
}

@media (max-width: 980px) {
  .Document {
    display: block;
  }
  .Document__left {
    min-width: 100%;
    max-width: 100%;
  }
  .Document__right {
    min-width: 100%;
    max-width: 100%;
    display: flex;
  }
  .Document__date {
    text-align: left;
    margin-bottom: 0;
    margin-right: 16px;
  }
}