.LinkToHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--el-height);
  height: var(--el-height);
  border-radius: var(--border-radius);
  background-color: var(--btn-back-color);
  cursor: pointer;
}
.LinkToHistory:hover {
  background-color: var(--item-back-hover-color);
}
.LinkToHistory__ico-container {
  width: 16px;
  height: 16px;
  display: block;
  background: url("./../../../../assets/link-to-history-ico.svg") no-repeat center / contain;
}