.TagLabel {
  background: var(--item-back-color);
  border-radius: var(--border-radius);
  height: calc(3*var(--distance-unit));
  line-height: calc(3*var(--distance-unit));
  padding: 0 calc(2*var(--distance-unit));;
  text-align: center;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--card-label-color);
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
}
.TagLabel:hover {
  color: var(--main-accent-color);
  background-color: var(--tag-label-color);
}