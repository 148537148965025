.Pagination__page-number {
    display: block;
    text-align: center;
    cursor: pointer;
    font-family: var(--main-font-family);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: var(--main-accent-color);
    height:40px;
    width: 40px;
    padding: 0 4px;
    margin:0 4px;
    border-radius: var(--border-radius);
}
/*.Pagination__pages-numbers-wrapper .Pagination__page-number:first-child{*/
    /*border-top-left-radius: 3px;*/
    /*border-bottom-left-radius: 3px;*/
/*}*/
/*.Pagination__pages-numbers-wrapper .Pagination__page-number:last-child{*/
    /*border-top-right-radius: 3px;*/
    /*border-bottom-right-radius: 3px;*/
/*}*/
.Pagination__page-number:hover {
    background-color: var(--list-item-inner-border-color);
}
.Pagination__page-number.Pagination__page-number_active {
    background-color: #ffffff;
    /*box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);*/
    border: 1px solid var(--sidebar-border-color);
  cursor: unset;
}
.Pagination {
    display: flex;
    justify-content: space-between;
}
.Pagination__pages-numbers-wrapper{
    display: flex;
}
.Pagination__pages{
    /*float:left;*/
}
.Pagination__count{
    display: flex;
    /*float:right;*/
}
.Pagination__page-number.Pagination__page-number_empty:hover {
    background-color: unset;
    cursor: unset;
}
.AddItemsModal__content-wrapper .Pagination__page-number {
    line-height: 24px;
    padding:0 8px;
    height:24px;
    font-size: 12px;
}
.Pagination__page-number.Pagination__page-number_scrollToTop {
    max-width: unset;
    width: auto;
}
.Pagination__page-number.Pagination__page-number_scrollToTop:hover {
    text-decoration: underline dotted;
    background-color: unset;
}