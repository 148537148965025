.ModalDeleteBtn {
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--modal-delete-btn-color);
    color: var(--modal-delete-btn-label-color);
    font-size: 12px;
    line-height: 16px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-family: var(--main-font-family);
    font-weight: 700;
}
.ModalDeleteBtn:hover {
    /*color: var(--main-accent-color);*/
    background-color: var(--item-back-hover-color);
}