.AddItemsModal__content-wrapper {
    width: 976px;
}
/* 100% - 100px - 150px - 150px - 100px - 124px */
/* 100% - 100px - 150px - 150px - 100px - 100px */
.AddItemRowHeading .AddContractRow__name {
    width: calc(100% - 100px - 150px - 150px - 100px - 124px);
    min-width: calc(100% - 100px - 150px - 150px - 100px - 124px);
}
.CheckboxInput__label .AddContractRow__name {
    width: calc(100% - 100px - 150px - 150px - 100px - 100px);
    min-width: calc(100% - 100px - 150px - 150px - 100px - 100px);
}
.AddContractRow__contractNo {
    width: 100px;
    min-width: 100px;
}
.AddContractRow__validityTerm {
    width: 150px;
    min-width: 150px;
}
.AddContractRow__statusDate {
    width: 100px;
    min-width: 100px;
}
.AddContractRow__contractTypeName {
    width: 150px;
    min-width: 150px;
}
.AddContractRow__statusLabel {
    width: 100px;
    min-width: 100px;
}
.SearchBox.SearchBox_in_AddItemsModal .Filters {
    margin-top: 8px;
    margin-bottom: 0;
}
.SearchBox.SearchBox_in_AddItemsModal .sorting-wrapper {
    margin-top: 0;
}
.AddItemsModal__list-wrapper {
    min-height: 345px;
}
.AddItemsModal__list-wrapper .CheckListContainer {
    max-height: 345px;
}
/* 920px - 100px - 100px - 124px */
/* 894px - 100px - 100px - 124px */
.CheckboxInput__label .AddAgentRow__name {
    width: calc(100% - 100px - 100px - 100px);
    min-width: calc(100% - 100px - 100px - 100px);
}
.AddItemRowHeading .AddAgentRow__name {
    width: calc(100% - 100px - 100px - 124px);
    min-width: calc(100% - 100px - 100px - 124px);
}
.AddAgentRow__createDate {
    width: 100px;
    min-width: 100px;
}
.AddAgentRow__statusLabel {
    width: 100px;
    min-width: 100px;
}
.AddAgentRow__statusDate {
    width: 100px;
    min-width: 100px;
}
.AddItemRowHeading {
    padding-left: 56px;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
}
.AddItemRowHeading .AddContractRow__statusDate,
.AddItemRowHeading .AddAgentRow__statusDate {
    width: 124px;
    min-width: 124px;
}
/*.AddItemRowHeading .AddContractRow__name {*/
/*    width: 306px;*/
/*    min-width: 306px;*/
/*}*/
/*.AddItemRowHeading .AddAgentRow__name {*/
/*    width: 521px;*/
/*    min-width: 521px;*/
/*}*/
/*976 24 32*/
/*896 - 100 - 100 - 90*/

input:-internal-autofill-selected {
    background-color: rgb(232, 240, 254) !important;
    background-image: none !important;
    color: -internal-light-dark-color(black, white) !important;
}
@media (max-width: 1050px) {
    .AddItemsModal__content-wrapper {
        width: 100%;
    }
    .AddItemRowHeading .AddContractRow__name {
        width: calc(100% - 100px - 150px - 100px - 124px);
        min-width: calc(100% - 100px - 150px - 100px - 124px);
    }
    .CheckboxInput__label .AddContractRow__name {
        width: calc(100% - 100px - 150px - 100px - 100px);
        min-width: calc(100% - 100px - 150px - 100px - 100px);
    }
    .AddContractRow__contractTypeName {
        display: none;
    }
}
@media (max-width: 900px) {
    .AddItemRowHeading .AddContractRow__name {
        width: calc(100% - 100px - 150px - 100px);
        min-width: calc(100% - 100px - 150px - 100px);
    }
    .CheckboxInput__label .AddContractRow__name {
        width: calc(100% - 100px - 150px - 100px);
        min-width: calc(100% - 100px - 150px - 100px);
    }
    .AddContractRow__statusDate{
        display: none;
    }
}