.SingleTagInput {
    color: var(--main-accent-color);
    font-size: 10px;
    overflow: hidden;
    box-sizing: border-box;
    line-height: 34px;
    font-family: var(--main-font-family);
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 2px;

    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /*z-index: 20;*/
    background-color: transparent;
    border: unset;
}
.SingleTagInput:focus {
    border: 1px solid var(--main-accent-color);
    max-width: 100%;
    text-transform: initial;
    padding: 0 2px;
    background-color: #ffffff;
}
.SingleTagInput__container {
    position: relative;
    display: flex;

    color: var(--main-accent-color);
    font-size: 10px;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    line-height: 34px;
    font-family: var(--main-font-family);
    font-weight: 600;
    text-transform: uppercase;
}
.SingleTagInput__hidden-value {
    color: var(--main-accent-color);
    font-size: 10px;
    overflow: hidden;
    padding: 0 2px;
    box-sizing: border-box;
    line-height: 34px;
    font-family: var(--main-font-family);
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0;
}