.BulkUpdateReportAccountStatus {
    display: flex;
}
.ChosenNumber-and-UpdateStatusBtn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-right: 1px solid var(--list-item-inner-border-color);
    min-width: 35%;
    max-width: 35%;
}
.Filters_and_1Cbutton {
    display: flex;
    min-width: 65%;
    max-width: 65%;
}
.AgentFilters {
    display: flex;
    min-width: calc(100% - 109px);
    max-width: calc(100% - 109px);
}
.AgentFilters .ReportsSeparateFilterFormGroup {
    padding: 16px;
    min-width: calc( (100% - 36px) / 2);
    max-width: calc( (100% - 36px) / 2);
}
.AgentFilters .ReportsSeparateFilterFormGroup .BorderlessSelect {
    max-width: 90%;
}
.AgentFilters__delimiter {
    width: 36px;
    border-right: 1px solid var(--list-item-inner-border-color);
    border-left: 1px solid var(--list-item-inner-border-color);
    background: url("./../../../../../assets/delimiter-arrow.svg") center / 24px 24px no-repeat;
}
@media (max-width: 1200px) {
    .BulkUpdateReportAccountStatus {
        display: block;
    }
    .ChosenNumber-and-UpdateStatusBtn-container {
        min-width: 100%;
        max-width: 100%;
        border-bottom: 1px solid var(--list-item-inner-border-color);
        border-right: unset;
    }
    .Filters_and_1Cbutton {
        min-width: 100%;
        max-width: 100%;
    }
}