.SearchInput {
  position: relative;
  /*height: 60px;*/
  border-radius: 3px;
  background-color: #fff;
  /*box-shadow: 0 20px 40px 0 rgba(0,0,0,.05);*/
  border: 1px solid var(--sidebar-border-color);
  z-index: 20;
  display: flex;
  padding-right: 80px;
  flex-wrap: wrap;
  padding-left: 43px;
}
.SearchInput__input {
  display: block;
  border: unset;
  width: 20px;
  /*min-width: 20px;*/
  /*width: 100%;*/
  /*max-width: 100%;*/
  background-color: transparent;
  height: 60px;
  line-height: 20px;
  padding: 20px 0;
  /*padding-left: 43px;*/
  margin-left: 4px;
  font-size: 17px;
  font-family: var(--main-font-family);
  color: var(--dark-text-color);
  outline: 0!important;
}
.SearchInput__input.SearchInput__input_ml_0{
  margin-left:0;
}
.SearchInput__input.SearchInput__input_full-width {
  width: 100%;
}
.SearchInput__input::placeholder{
  color: var(--sec-text-soft-color);
}
input.SearchInput__input:-internal-autofill-selected {
  background-color: transparent !important;
  color: var(--dark-text-color) !important;
}
.SearchInput__spinner {
  z-index: 100;
  position: absolute;
  left: 1px;
  top: 1px;
  height: 58px;
  min-width: 40px;
  display: flex;
  cursor: pointer;
  background: #fff center / 24px no-repeat url('../../../../../../assets/ajax-loader.gif');
}
.SearchInput__spinner.SearchInput__spinner_hide {
  display: none;
}
.SearchInput__search-ico {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 20;
}
/*.SearchInput__tags-container {*/
  /*display: flex;*/
  /*margin-left: 40px;*/
  /*line-height: 60px;*/
  /*align-items: center;*/
  /*flex-wrap: wrap;*/
/*}*/
.SearchInput__tag {
  background-color: var(--tag-label-color);
  border-radius: 5px;
  display: flex;
  margin: 13px 4px 0 4px;
  line-height: 34px;
  height: 34px;
}
.SearchInput__tag-label {
  color: var(--main-accent-color);
  font-size: 10px;
  overflow: hidden;
  padding: 0px 15px 0px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  line-height: 34px;
  font-family: var(--main-font-family);
  font-weight: 600;
  text-transform: uppercase;
}
.SearchInput__tag-delete {
  align-items: center;
  border-radius: 2px;
  background-color: transparent;
  display: flex;
  padding-left: 13px;
  padding-right: 13px;
  box-sizing: border-box;
  line-height: 34px;
  color: var(--main-accent-color);
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
}
.SearchInput__tag-delete:hover {
  color: var(--warning-color);
  background-color: var(--tag-label-del-ico-hover-color);
}
.SearchInput__input.SearchInput__input_border{
  border:1px solid var(--form-field-border-color);
}

.SearchBox_in_AddItemsModal .SearchInput {
  border-radius: var(--border-radius);
  border: 1px solid var(--form-field-border-color);
  height: var(--el-height);

  box-shadow: unset;
}
.SearchBox_in_AddItemsModal .SearchInput__spinner{
  height: 38px;
  top: 0;
}
.SearchBox_in_AddItemsModal .SearchInput__input{
  padding: 0 12px;
  height: var(--el-height);
  line-height: var(--el-height);
}
.SearchBox.SearchBox_in_AddItemsModal .SearchPropName {
  top: 0px;
  right: 4px;
}
.SearchBox.SearchBox_in_AddItemsModal .DropDownSelect__option {
  padding: 0 12px;
  height: var(--el-height);
  line-height: var(--el-height);
}
.SearchBox.SearchBox_in_AddItemsModal .DropDownSelect {
  padding: 0;
}
.SearchBox.SearchBox_in_AddItemsModal .DropDownSelect__ico {
  top: 12px;
}
.SearchBox.SearchBox_in_AddItemsModal .SearchInput__search-ico {
  display: none;
}