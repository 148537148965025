.PasswordRuleLabel {
  display: flex;
}
.PasswordRuleLabel__ico {
  width: 16px;
  height: 16px;
  background-image: url('./../../../../assets/rule-ico.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.PasswordRuleLabel__text {
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--rule-color);
}
.PasswordRuleLabel.PasswordRuleLabel_ruleIsRight .PasswordRuleLabel__ico {
  background-image: url('./../../../../assets/rule-ico-active.svg');
}
.PasswordRuleLabel.PasswordRuleLabel_ruleIsRight .PasswordRuleLabel__text {
  color: var(--rule-active-color);
}