.FilterAndActInfoPanel {
    width: 100%;
    padding: 4px;
    height: 48px;
    /*background-color: var(--item-back-color);*/
    background-color: var(--btn-accent-color);
    display: flex;
    justify-content: space-between;
}
.FilterAndActInfoPanel__left {
    display: flex;
    align-items: center;
    padding-left: 12px;
}
.FilterAndActInfoPanel__right {
    display: flex;
    align-items: center;
}
.FilterAndActInfoPanel__rightBtns {
    display: flex;
    align-items: center;
}
.FilterAndActInfoPanel__container.FilterAndActInfoPanel_sticky {
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--header-height) + var(--subheader-height) + 57px + 72px);
    z-index: 1000;
}
.FilterAndActInfoPanel__whiteBottom {
    height: 16px;
    /*background-color: var(--btn-accent-color);*/
    background-color: var(--new-back-color);
}


@media (max-width: 1050px) {
    .FilterAndActInfoPanel {
        display: block;
        height: auto;
    }
    .FilterAndActInfoPanel__right {
        margin-top: calc(1 * var(--distance-unit));
        padding-left: 12px;
    }
}
@media (max-width: 860px) {
    .FilterAndActInfoPanel__right {
        display: block;
    }
    .FilterAndActInfoPanel__rightBtns {
        margin-top: var(--distance-unit);
    }
}