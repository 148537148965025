.Text1 {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);
}
.Text1.Text1_error {
  color: var(--error-color);
}
.Text1.Text1_warning{
  color: var(--unloaded-color);
}
.Text1.Text1_D,
.Text1.Text1_terminalStatus,
.Text1.Text1_gateStatus,
.Text1.Text1_card-label-color {
  color: var(--card-label-color);
}
.Text1.Text1_report-contract-link-color {
  color: var(--report-contract-link-color);
}
a .Text1.Text1_report-contract-link-color:hover {
  color: var(--main-accent-color);
}
.Text1.Text1_success {
  color: var(--prepared-color);
}
.Text1.Text1_lh20 {
  line-height: 20px;
}
.Text1.Text1_lh24 {
  line-height: 24px;
}
.Text1.Text1_lh40 {
  line-height: 40px;
}
.Text1.Text1_link{
  cursor: pointer;
  color:var(--main-accent-color);
  text-decoration: underline;
}
.Text1.Text1_link:hover{
  text-decoration: none;
}
.Text1.Text1_link.Text1_link_disabled,
.Text1.Text1_link.Text1_link_disabled:hover {
  opacity: .5;
  cursor: unset;
  text-decoration: underline;
}
.Text1.Text1_history-link {
  color: var(--alert-notification-color);
  cursor: pointer;
  max-width: 100%;
}
.Text1.Text1_history-link:hover {
  color:var(--main-accent-color);
}
.Text1.Text1_fw500 {
  font-weight: 500;
}
.Text1.Text1_bold {
  font-weight: bold;
}
.Text1.Text1_italic {
  font-style: italic;
}
.Text1.Text1_uppercase {
  text-transform: uppercase;
}
.Text1.Text1_underline-and-link {
  text-decoration: underline;
  cursor: pointer;
}
.Text1.Text1_underline-and-link:hover {
  text-decoration: none;
}
.Text1.Text1_asHeaderBtn {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(2*var(--distance-unit));
}
.Text1.Text1_asBtnNewItem {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(2*var(--distance-unit));
}
.Text1.Text1_E {
  color: var(--alert-success-color);
}
.Text1.Text1_fs12 {
  font-size: 12px;
}
