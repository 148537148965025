.GroupFinTermCardParamsRow {
  width: 100%;
  position: relative;
  padding-right: calc(3*var(--distance-unit));
  display: flex;
  justify-content: space-between;
}
.GroupFinTermCardParamsRow__dates {
  width: 190px;
  min-width: 190px;
}
.GroupFinTermCardParamsRow__ranges {
  width: 530px;
  min-width: 530px;
}
.GroupFinTermCardParamsRow__date-dash {
  min-width: 30px;
  text-align: center;
}
.GroupFinTermCardParamsRow__percent {
  text-align: right;
  width: 200px;
  min-width: 200px;
}
.GroupFinTermCardParamsRow__file-ico-container {
  width: 16px;
  height: 16px;
  min-width: 16px;
  position: absolute;
  right: -6px;
  top: 0;
}
.GroupFinTermCardParamsRow__file-ico {
  display: block;
}
.GroupFinTermCardParamsRow__range-amount {
  width: 290px;
  margin: 0 calc(2*var(--distance-unit));
  text-align: right;
  display: flex;
}
.GroupFinTermCardParamsRow__range-amount-left {
  width: 130px;
  min-width: 130px;
  text-align: right;
}
.GroupFinTermCardParamsRow__range-amount-dash {
  width: 30px;
  min-width: 30px;
  text-align: center;
}
.GroupFinTermCardParamsRow__range-amount-right {
  width: 130px;
  min-width: 130px;
  text-align: left;
}
.GroupFinTermCardParamsRow__range {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1150px) {
  .GroupFinTermCardParamsRow {
    display: block;
  }
  .GroupFinTermCardParamsRow__dates {
    margin-bottom: var(--distance-unit);
  }
  .GroupFinTermCardParamsRow__ranges {
    width: 100%;
    min-width: 100%;
  }
  .GroupFinTermCardParamsRow__range {
    justify-content: space-between;
  }
  .GroupFinTermCardParamsRow__percent {
    text-align: left;
    width: 40%;
    min-width: 40%;
  }
  .GroupFinTermCardParamsRow__range-amount {
    width: 50%;
    margin: 0;
  }
}
@media (max-width: 930px) {
  .GroupFinTermCardParamsRow__range {
    display: block;
  }
  .GroupFinTermCardParamsRow__percent {
    width: 100%;
    min-width: 100%;
  }
  .GroupFinTermCardParamsRow__range-amount {
    width: 100%;
    justify-content: flex-end;
  }
  .GroupFinTermCardParamsRow__range-amount-left {
    width: auto;
    min-width: auto;
  }
  .GroupFinTermCardParamsRow__range-amount-right {
    width: auto;
    min-width: auto;
  }
}