.Tooltip {
	display: inline-block;
	position: absolute;
	z-index: 20;
	right: 0;
	top: -40px;
	font-family: var(--main-font-family);
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
	color: #3537e5;
	line-height: 2;
	padding: 0 15px;
	background-color: #fff;
	border: 1px solid #3537e5;
	border-radius: 3px;
	opacity: 0;
	transition: opacity .1s ease;
}
.Tooltip::after {
	display: inline-block;
	content: '';
	z-index: 30;
	position: absolute;
	top: 20px;
	left: 16px;
	width: 12px;
	height: 12px;
	transform: rotate(45deg);
	border-right: 1px solid #3537e5;
	border-bottom: 1px solid #3537e5;
	background-color: #fff;
}
.Tooltip.Tooltip_animatedOpacity {
	animation-name: animatedOpacity;
	animation-duration: 1s;
}
@keyframes animatedOpacity {
	0% {opacity: 0;}
	10% {opacity: 1;}
	90% {opacity: 1;}
	100% {opacity: 0;}
}