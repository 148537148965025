.ColumnMenu {

}
.ColumnMenu__options {
    display: flex;
    flex-direction: column;
}
.ColumnMenuItem {
    border-radius: 1px;
    padding: 12px 10px;
    color: var(--main-text-color);
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    border-left: 2px solid transparent;
}
.ColumnMenuItem.ColumnMenuItem_active {
    color: var(--main-accent-color);
    background-color: var(--bg-secondary);
    border-left: 2px solid var(--main-accent-color);
}
.ColumnMenuItem:hover {
    color: var(--main-accent-color);
}