.FilterSelect {
  /*display: flex;*/
}
.FilterSelect.FilterSelect_disabled {
  opacity: .5;
}
.FilterSelect:hover > div > div:first-child,
.FilterSelect:hover > div > span + div {
  background-color: var(--btn-del-grey-color);
  border: 1px solid transparent;
}
/*.FilterSelect:hover > div > span + div > div:first-child > div:first-child,*/
/*.FilterSelect:hover > div > div:first-child > div:first-child > div:first-child {*/
/*  color: var(--btn-accent-color);*/
/*}*/
.AddFilterBtn__wrapper {
  background-color: var(--item-back-color);
  padding-left: 2px;
  margin-left: 16px;
  margin-top: -1px;
}
.FilterSelect .StatusLabel {
  display: inline;
}