.SideRightContainer {
    position: relative;
    padding-top: var(--distance-unit);
}
.SideRightContainer + .SideRightContainer {
    padding-top: calc(4*var(--distance-unit));
}
.FixedHeader + .SideLeftContainer,
.FixedHeader + .SideLeftContainer + .SideRightContainer,
.SideLeftContainer + .SideRightContainer + .SideRightContainer {
    padding-top: 0;
}
.FixedSubHeader ~ .SideRightContainer,
.RowMenu ~ .SideRightContainer {
    top: calc(var(--subheader-height) - 3*var(--distance-unit));
}

.SideLeftContainer ~ .SideRightContainer {
    margin-left: calc(var(--subheader-left-width) + var(--subheader-left-margin));
}
.SideRightContainer.SideRightContainer--submenu-fixed  {
    z-index: 1;
    position: sticky;
    top: calc(var(--header-height) + var(--subheader-height) - 10px);
    border: 1px solid var(--new-back-color);;
}

.GatesParams__filters-container {
    position: sticky;
    top: 0;
    border: 1px solid var(--new-back-color);;
}