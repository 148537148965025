.SearchSheet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #FFFFFF;
}
.SearchSheet__header-back {
  height: 136px;
  background-color: var(--back-hover-color);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.SearchSheet__content {
  width: 1440px;
  position: relative;
  z-index: 20;
  margin: 0 auto;
  padding: 40px 32px;
}
.SearchInOrgContextInput {
  width: calc(100% - 100px);
  border: unset;
  height: 56px;
  line-height: 56px;
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  color: var(--main-accent-color);
  margin-bottom: 72px;
  background-color: transparent;
}
.SearchInOrgContextResults__before-search-image {
  width: 604px;
  height: 558px;
  margin: 60px auto 0;
  background-image: url("./../../../assets/before-search-image.png");
}
@media (max-width: 1460px) {
  .SearchSheet__content {
    width: 100%;
  }

}