.TurnOnOffBtn {
    width: 18px;
    min-width: 18px;
    height: 12px;
    border-radius: 6px;
    background-color: var(--card-label-color);
    padding: 2px;
    transition: all .5s ease;
    cursor: pointer;
}
.TurnOnOffBtn.TurnOnOffBtn_isOn {
    background-color: var(--alert-success-color);
}
.TurnOnOffBtn__circle {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--btn-accent-color);
    transition: all .5s ease;
}
.TurnOnOffBtn.TurnOnOffBtn_isOn .TurnOnOffBtn__circle {
    transform: translateX(6px);
}
.TurnOnOffBtn:disabled {
    opacity: .5;
    cursor: unset;
}