body {
  margin: 0;
  padding: 0;
  line-height: 1; }
* {
  box-sizing: border-box;
}

a {
  display: block;
  text-decoration: none; }

b {
  margin: 0; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal; }

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0; }

button {
  display: block;
  border: none;
  padding: 0;
  background-color: unset;
}
button:focus {
  outline: unset;
}
textarea, input {
  display: block;
  padding: 0;
  margin: 0;
}
textarea:focus, input:focus {
  outline: unset;
}

/*select,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}  */
img{
  max-width:100%;
  max-height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}