.MainMenuItem {
  padding: calc(2*var(--distance-unit)) 0;
  position: relative;
  cursor: pointer;
}
.MainMenuItem__linkText {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  text-transform: uppercase;
  color: var(--main-text-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .268s ease-out .268s;
}
.MainMenuItem__unviewedIcoComp {
  position: relative;
  height: 4px;
}
.MainMenuItem__unviewedIcoComp .HistoryMenuUnviewedMark{
  position: absolute;
  right: -10px;
}
.MainMenuItem__icon-container {
  height: 24px;
  width: 24px;
  display: block;
  margin: 0 auto var(--distance-unit);
  /*margin-right: 16px;*/
  position: relative;
}
.MainMenuItem__ledge {
  position: absolute;
  top: 3px;
  left: 0;
  bottom: 3px;
  width: 3px;
  /*height: 36px;*/
  background-color: var(--main-accent-color);
  border-radius: 0px 2px 2px 0px;
  opacity: 0;
}
.MainMenuItem.MainMenuItem_active .MainMenuItem__ledge{
  opacity: 1;
}
.MainMenuItem.MainMenuItem_active {
  background-color: var(--back-hover-color);
}
.MainMenuItem:hover {
  background-color: var(--back-hover-color);
}

.MainMenuItem:hover,
.MainMenuItem:hover .MainMenuItem__linkText,
.MainMenuItem:hover .Heading4 {
  color: var(--main-accent-color);
}
.MainMenuItem.MainMenuItem_active .MainMenuItem__linkText,
.MainMenuItem.MainMenuItem_active:hover .MainMenuItem__linkText {
  color: var(--main-accent-color);
}
.MainMenuItem:hover .button__icon {
  opacity: 0;
}
.MainMenuItem:hover .button__icon.button__icon_hover {
  opacity: 1;
}
.MainMenuItem.MainMenuItem_active .button__icon,
.MainMenuItem.MainMenuItem_active .button__icon.button__icon_hover,
.MainMenuItem.MainMenuItem_active:hover .button__icon,
.MainMenuItem.MainMenuItem_active:hover .button__icon.button__icon_hover {
  opacity: 0;
}
.MainMenuItem.MainMenuItem_active .button__icon.button__icon_active,
.MainMenuItem.MainMenuItem_active:hover .button__icon.button__icon_active {
  opacity: 1;
}
.MainMenuItem_mw271 {
  max-width: 271px;
}