.BtnNewItem {
  background-color: var(--main-accent-color);
  border-radius: var(--border-radius);
  height: var(--el-height);
  font-family: var(--heading-font-family);
  color: var(--btn-accent-color);
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: var(--el-height);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
}
.upload-doc__btn-container:hover .BtnNewItem,
.BtnNewItem:hover {
  background-color: var(--btn-accent-hover-color);
}
.BtnNewItem.BtnNewItem_inverse {
  border: 1px solid var(--main-accent-color);
  background-color: transparent;
  color: var(--main-accent-color);
}
.BtnNewItem.BtnNewItem_inverse:hover {
  background-color: var(--main-accent-color);
  color: var(--btn-accent-color);
}
.BtnNewItem:disabled {
  opacity: .5;
  cursor: unset;
}
.BtnNewItem:disabled:hover {
  background-color: var(--main-accent-color);
}
.BtnNewItem.BtnNewItem_inverse.BtnNewItem_disabled {
  opacity: .5;
  cursor: unset;
}
.BtnNewItem.BtnNewItem_inverse:disabled:hover,
.BtnNewItem.BtnNewItem_inverse.BtnNewItem_disabled:hover {
  background-color: transparent;
  color: var(--main-accent-color);
  opacity: .5;
  cursor: unset;
}
.BtnNewItem.BtnNewItem_full-width {
  width: 100%;
}

.BtnNewItem.BtnNewItem_style-v2 {
  height: 38px;
  line-height: 38px;
  width: 100%;
}
.BtnNewItem.BtnNewItem_minWidth160 {
  min-width: 160px;
}

.BtnNewItem.BtnNewItem_grey {
  border: unset;
  background-color: var(--btn-new-item-grey-color);
  color: var(--main-text-color);
  font-size: 10px;
  padding-left: calc(3 * var(--distance-unit));
  padding-right: calc(3 * var(--distance-unit));
}
.BtnNewItem.BtnNewItem_grey:hover {
  background-color: var(--main-accent-color);
  color: var(--btn-accent-color);
}

.BtnNewItem.BtnNewItem_transparent {
  border: unset;
  background-color: transparent;
  color: var(--main-accent-color);
  font-size: 10px;
  padding-left: calc(3 * var(--distance-unit));
  padding-right: calc(3 * var(--distance-unit));
}
.BtnNewItem.BtnNewItem_transparent:hover {
  background-color: var(--btn-new-item-grey-color);
  color: var(--main-text-color);
}

.BtnNewItem.BtnNewItem_grey:hover .BackButtonIcon {
  background: url("./../../../../assets/back-button-white-icon.svg") center / contain no-repeat;
}