.DynamicTypeFormField__container {
  position: relative;
}
.pr_forIco {
  padding-right: calc(40px + var(--distance-unit));
}
.filter-dateRange-propNameLabel {
  background-color: var(--filter-select-back-color);
  /*background-color: var(--item-back-color);*/
  color: var(--main-text-color);
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: var(--el-height);
  height: var(--el-height);
  border-radius: var(--filter-select-border-radius);
  padding-left: calc(2*var(--distance-unit));
}
.filter-dateRange-strictPeriodCheckbox {
  background-color: var(--filter-select-back-color);
  /*background-color: var(--item-back-color);*/
  color: var(--main-accent-color);
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: normal;
  line-height: var(--el-height);
  height: var(--el-height);
  padding-left: calc(2*var(--distance-unit));
  padding-right: calc(2*var(--distance-unit));
}
.filter-dateRange-strictPeriodCheckbox .CheckboxInput .Checkbox {
  height: 16px;
  width: 16px;
  margin-right: calc(1*var(--distance-unit));
}
.filter-dateRange-strictPeriodCheckbox .CheckboxInput {
  padding: 12px 0;
}