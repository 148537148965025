.EmptyListV2 {
    background: var(--back-hover-color);
    border: 1px dashed var(--main-accent-color);
    border-radius: 2px;
    height: 462px;
    max-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.EmptyListV2__img {
    width: 213px;
    height: 213px;
    max-width: 50%;
    max-height: 50%;
    background: url("./../../../../assets/empty-list-v2-img.svg") no-repeat center;
    margin-bottom: calc(3*var(--distance-unit));
}
.EmptyListV2__label {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--main-accent-color);

}