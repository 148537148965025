.sorting-wrapper{
    background-color: #FFFFFF;
    /*box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);*/
    border: 1px solid var(--sidebar-border-color);
    border-radius: var(--card-border-radius);
}
.sorting-link{
    font-size: 14px;
    line-height: 20px;
    margin-right:35px;
    position: relative;
    cursor: pointer;
    color:var(--sec-text-color);
}
.sorting-link.sorting-link_active{
    color:var(--main-accent-color);
}
.sorting-link:before,
.sorting-link.sorting-link_active.down:before{
    content:'';
    height:4px;
    width:8px;
    position:absolute;
    left:0;
    top:3px;
    background: url(../../../../../assets/sort-ico-up.png) no-repeat left top;
}
.sorting-link:after,
.sorting-link.sorting-link_active.up:after{
    content:'';
    height:4px;
    width:8px;
    position:absolute;
    left:0;
    bottom:3px;
    background: url(../../../../../assets/sort-ico-down.png) no-repeat left top;
}
.sorting-link.sorting-link_active.up:before{
    content:'';
    height:4px;
    width:8px;
    position:absolute;
    left:0;
    top:3px;
    background: url(../../../../../assets/sort-ico-up-active.png) no-repeat left top;
}
.sorting-link.sorting-link_active.down:after{
    content:'';
    height:4px;
    width:8px;
    position:absolute;
    left:0;
    bottom:3px;
    background: url(../../../../../assets/sort-ico-down-active.png) no-repeat left top;
}