.SearchBox {
  position: relative;
}
.SearchBox .SearchPropName {
  position: absolute;
  top: 5px;
  z-index: 20;
  right: 64px;
}
.SearchBox_SearchPropName .SearchInput__input.SearchInput__input_full-width {
  padding-right: 240px;
}
.SearchPropName .DropDownSelect {
  width: 238px;
}