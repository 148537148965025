.DataListContainer {
  background-color: var(--item-back-color);
  border-radius: var(--card-border-radius);
  padding: 0 calc(3*var(--distance-unit));
}
.DataListContainer__item {
  padding: calc(2*var(--distance-unit)) 0;
  border-bottom: 1px solid var(--header-border-color);
}
.DataListContainer__item:last-child {
  border-bottom: unset;
}
.DataListContainer.DataListContainer_withGroupFinTermBlocks > .DataListContainer__item {
  border-bottom: unset;
  padding: calc(2*var(--distance-unit)) 0 0;
}