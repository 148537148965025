.FilterFlags__container {
  display: flex;
  /*margin-top: calc(-3*var(--distance-unit));*/
  padding-top: calc(3*var(--distance-unit));
  padding-bottom: calc(2*var(--distance-unit));
  background-color: var(--new-back-color);
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-height);
  z-index: 20;
}
.FilterFlags {
  display: flex;
}
.FilterFlags__item {
  display: flex;
  align-items: center;
  background-color: var(--item-back-hover-color);
  padding: 0 20px;
  height: var(--el-height);
  line-height: var(--el-height);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--main-text-color);
  border-radius: 5px;
  margin-right: 4px;
  cursor: pointer;
}
.FilterFlags__item:hover {
  background-color: var(--main-accent-color);
  color: var(--btn-accent-color);
}
.FilterFlags__item.FilterFlags__item_active {
  background-color: var(--main-accent-color);
  color: var(--btn-accent-color);
  cursor: unset;
}
.FilterFlags__item:last-child {
  margin-right: 0;
}
.FilterFlags__item .UnviewedMark {
  display: none;
}
.FilterFlags__item .UnviewedMark.UnviewedMark_on {
  display: block;
  margin-right: 10px;
}