.ActTemplateCard__name-and-btns {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.ActTemplateCard__name {
    width: var(--half-card-width);
    max-width: var(--half-card-width);
}
.ActTemplateCard__btns {
    display: flex;
    align-items: center;
}
.ActTemplateCard__files {
    display: flex;
}
.ActTemplateCard__addendum-file {
    display: flex;
    margin-left: calc(4*var(--distance-unit));
}
@media (max-width: 1150px) {
    .ActTemplateCard__name-and-btns {
        display: block;
    }
    .ActTemplateCard__name {
        width: 100%;
        max-width: 100%;
    }
    .ActTemplateCard__btns {
        justify-content: flex-end;
        margin-top: var(--distance-unit);
    }
}
@media (max-width: 950px) {
    .ActTemplateCard__files {
        display: block;
    }
    .ActTemplateCard__addendum-file {
        margin-left: 0;
        margin-top: var(--distance-unit);
    }
}