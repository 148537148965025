.ImportComponent {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.ImportComponent .StyledLink {
	display: block;
	margin-right: 8px;
}
.Pre.Pre_codeIsCopied {
	background-color: #a5a2a2;
}
.Pre.Pre_in_ImportComponent {
	position: relative;
}
