.TagToCopy {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: var(--main-accent-color);
    border-bottom: 1px dashed transparent;
    cursor: pointer;
}
.TagToCopy:hover {
    border-bottom: 1px dashed var(--main-accent-color);
}
.TagToCopy.TagToCopy_fs14 {
    font-size: 14px;
}
.TagToCopy.TagToCopy_fwn {
    font-weight: normal;
}