.HistoryCard {

}
.HistoryCard__action-row {
  position: relative;
  cursor: pointer;
}
.HistoryCard__action-row .UnviewedMark {
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.HistoryCard__1st-col {
  min-width: 60px;
  max-width: 60px;
}
.HistoryCard__2nd-col {
  /*min-width: 420px;*/
  min-width: calc(0.4 * (100% - 92px));
  max-width: calc(0.4 * (100% - 92px));
}
.HistoryCard__3rd-col {
  display: flex;
  /*min-width: 480px;*/
  min-width: calc(0.6 * (100% - 92px));
  max-width: calc(0.6 * (100% - 92px));
}
.NDropDownDetails.NDropDownDetails_in_HistoryCard .DataListContainer__item,
.NDropDownDetails.NDropDownDetails_in_HistoryCard .DataListContainer {
  padding: 0;
}
.NDropDownDetails.NDropDownDetails_in_HistoryCard .NDropDownDetails__summary-container {
  padding: 0;
  text-transform: unset;
  cursor: unset;
  background-color: transparent;
  z-index: 0;
}
.NDropDownDetails.NDropDownDetails_in_HistoryCard .NDropDownDetails__ico {
  top: 0;
  bottom: unset;
  right: 0;
  cursor: pointer;
}