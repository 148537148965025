.ViewTemplateParams__block {
    /*border-top: 1px solid var(--label-color);*/
    /*border-bottom: 1px solid var(--label-color);*/
}
.ViewTemplateParams__paramProp {
    width: 48%;
}
.ViewTemplateParams__table-header {
    display: flex;
    flex-wrap: nowrap;
}
.ViewTemplateParams__1st-col {
    width: 244px;
    max-width: 244px;
}
.ViewTemplateParams__2nd-col {
    width: 244px;
    max-width: 244px;
}
.ViewTemplateParams__3rd-col {
    width: 244px;
    max-width: 244px;
}
.ViewTemplateParams__3rd-col.ViewTemplateParams__3rd-col_wide {
    width: 488px;
    max-width: 488px;
}
.ViewTemplateParams__4th-col {
    width: 244px;
    max-width: 244px;
}
.ViewTemplateParams__color-back {
    background-color: var(--item-back-color);
}
.ViewTemplateParams__table-header .ViewTemplateParams__1st-col,
.ViewTemplateParams__table-header .ViewTemplateParams__2nd-col,
.ViewTemplateParams__table-header .ViewTemplateParams__3rd-col,
.ViewTemplateParams__table-header .ViewTemplateParams__4th-col {
    border: 1px solid var(--form-field-border-color);
    display: flex;
    align-items: center;
}