.BtnEditAct {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-accent-color);
  cursor: pointer;
}
.BtnEditAct:hover {
  text-decoration: underline;
}
.BtnEditAct.BtnEditAct_delete {
  color: var(--warning-color);
}
.BtnEditAct.BtnEditAct_delete:disabled {
  cursor: unset;
  color: var(--card-label-color);
}
.BtnEditAct.BtnEditAct_delete:disabled:hover {
  text-decoration: unset;
}
.BtnEditAct.BtnEditAct_addBlock {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.BtnEditAct.BtnEditAct_edit {
  color: var(--card-label-color);
}
.FinTermParamsRow .BtnEditAct.BtnEditAct_delete {
  margin-left: auto;
}
.BtnEditAct.BtnEditAct_lh24 {
  line-height: 24px;
}
.BtnEditAct.BtnEditAct_lh20 {
  line-height: 20px;
}
.BtnEditAct.BtnEditAct_dashed {
  text-decoration: underline dotted;
}
.BtnEditAct.BtnEditAct_dashed:hover {
  text-decoration: underline;
}
.BtnEditAct:disabled {
  opacity: .3;
  cursor: unset;
}
.BtnEditAct:disabled:hover {
  text-decoration: unset;
}
.BtnEditAct.BtnEditAct_dashed:disabled:hover {
  text-decoration: underline dotted;
}
.BtnEditAct.BtnEditAct_isChooseActive {
  color: var(--card-label-color);
}