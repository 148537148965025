.AgentCard__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.AgentCard__top-right {
  display: flex;
  align-items: center;
}

