.TabSteps {
    display: flex;
    flex-wrap: wrap;
}
.TabStepsItem {
    line-height: 40px;
    height: 40px;
    font-weight: 700;
    font-size: 12px;
    font-family: var(--heading-font-family);
    color: var(--sidebar-border-color);
    background-color: var(--item-back-color);
    text-transform: uppercase;
    padding: 0 24px;
    cursor: pointer;
    clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%, 10px 50%);
    margin-bottom: var(--distance-unit);
}
.TabStepsItem:first-child {
    border-radius: 4px 0 0 4px;
    clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%);
}
.TabStepsItem:last-child {
    border-radius: 0 4px 4px 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10px 50%);
}
.TabStepsItem.TabStepsItem_alreadyActive {
    background-color: var(--main-accent-color);
}
.TabStepsItem.TabStepsItem_active {
    background-color: var(--main-accent-color);
    color: #ffffff;
    cursor: unset;
}