.ArrowIcon {
  width: 16px;
  height: 16px;
  background: url("./../../../../assets/v3-arrow-blue.svg") center / contain no-repeat;
}
.ArrowIcon.ArrowIcon_in-NHeading1 {
  width: 24px;
  height: 24px;
}
.ArrowIcon.ArrowIcon_sortTop {
  background: url("./../../../../assets/arrow-sort-top-icon.svg") center / 16px 16px no-repeat;
}
.ArrowIcon.ArrowIcon_sortDown {
  background: url("./../../../../assets/arrow-sort-down-icon.svg") center / 16px 16px no-repeat;
}
.CustomDropDownSelect__option.CustomDropDownSelect__option_active .ArrowIcon.ArrowIcon_sortTop {
  background: url("./../../../../assets/arrow-sort-top-icon-active.svg") center / 16px 16px no-repeat;
}
.CustomDropDownSelect__option.CustomDropDownSelect__option_active .ArrowIcon.ArrowIcon_sortDown {
  background: url("./../../../../assets/arrow-sort-down-icon-active.svg") center / 16px 16px no-repeat;
}
