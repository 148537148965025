/*.NCard.ContractCard {*/
/*  padding: 0;*/
/*  display: grid;*/
/*  grid-template-columns: 575px 257px;*/
/*}*/
.ContractCard__left {
  padding: calc(3*var(--distance-unit)) 0 calc(3*var(--distance-unit)) calc(3*var(--distance-unit));
}
.ContractCard__right {
  border-left: 1px solid var(--item-back-color);
  padding: calc(3*var(--distance-unit)) calc(3*var(--distance-unit)) calc(8*var(--distance-unit));
  position: relative;
}
.ContractCard__left-1st-row {
  padding-bottom: calc(2*var(--distance-unit));
  border-bottom: 1px solid var(--item-back-color);
}
.ContractCard__left-2nd-row {
  padding: calc(2*var(--distance-unit)) 0;
}
.ContractCard__tags {
  /*padding-top: calc(2*var(--distance-unit));*/
  border-top: 1px solid var(--item-back-color);
  display: flex;
  flex-wrap: wrap;
}
.ContractCard__tags .TagLabel {
  margin-top: calc(2*var(--distance-unit));
  margin-right: var(--distance-unit);
}
.ContractCard__tiles .TileIcon {
  margin-right: 6px;
}
.ContractCard__tags .TagLabel:last-child,
.ContractCard__tiles .TileIcon:last-child {
  margin-right: 0;
}
.ContractCard__tiles {
  position: absolute;
  left: calc(3*var(--distance-unit));
  right: calc(3*var(--distance-unit));
  bottom: calc(3*var(--distance-unit));
  display: flex;
}
