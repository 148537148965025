.NLabel {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--label-color);
  display: block;
}
label.NLabel {
  display: block;
}
.NLabel.NLabel_empty {
  min-height: 0;
}