.StatusLabel {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.StatusLabel.StatusLabel_CREATED {
  color: var(--card-label-color)
}
.StatusLabel.StatusLabel_ACTIVE,
.StatusLabel.StatusLabel_UPLOADED,
.StatusLabel.StatusLabel_VERIFIED {
  color: var(--prepared-color);
}
.StatusLabel.StatusLabel_UNVERIFIED,
.StatusLabel.StatusLabel_TERMINATED,
.StatusLabel.StatusLabel_DRAFT,
.StatusLabel.StatusLabel_INACTIVE {
  color: var(--alert-report-warning-color);
}
.StatusLabel.StatusLabel_bold {
  font-weight: 700;
}
.CheckboxInput.CheckboxInput_fs12 .CheckboxInput__label .StatusLabel {
  font-size: 12px;
  line-height: 16px;
}