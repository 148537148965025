.GroupFinTermCardLabel {
  font-family: var(--heading-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--card-label-color);
}
.GroupFinTermCardLabel.GroupFinTermCardLabel_dark {
  color: var(--dark-text-color);
}
.GroupFinTermCardLabel.APPROVED {
  color: var(--status-color);
}
.GroupFinTermCardLabel.DECLINED {
  color: var(--error-color);
}
.GroupFinTermCardLabel.GroupFinTermCardLabel_no-uppercase-fw500 {
  text-transform: unset;
  font-weight: 500;
}
.GroupFinTermCardLabel.GroupFinTermCardLabel_lh17 {
  line-height: 17px;
}
.GroupFinTermCardLabel.GroupFinTermCardLabel_italic {
  font-style: italic;
}