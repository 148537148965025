.AddFilterBtn {
  display: block;
  height: var(--el-height);
  width: var(--el-height);
  border-radius: var(--filter-select-btn-border-radius);
  cursor: pointer;
  background: var(--filter-select-back-color) url("./../../../../assets/add-filter-btn-icon.svg") center / 16px 16px no-repeat;
  /*background: var(--item-back-color) url("./../../../../assets/add-filter-btn-icon.svg") center / 16px 16px no-repeat;*/
}
.AddFilterBtn.AddFilterBtn_active,
.FilterSelect:hover .AddFilterBtn,
.AddFilterBtn:hover {
  background: var(--btn-del-grey-color) url("./../../../../assets/add-filter-btn-hover-icon.svg") center / 16px 16px no-repeat;
}