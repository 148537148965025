.NInput {
  background-color: #ffffff;
  border-radius: var(--border-radius);
  border: 1px solid var(--form-field-border-color);
  height: var(--el-height);
  padding: 0 12px;
  line-height: var(--el-height);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--dark-text-color);
  width: 100%;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.NInput:disabled,
.NInput:read-only {
  opacity: .5;
}
.NInput.ViewTemplateParams__NInput {
  height: 24px;
  line-height: 24px;
}
.NInput.ViewTemplateParams__NInput:read-only {
  opacity: 1;
}
input.NInput:-internal-autofill-selected {
  background-color: #ffffff !important;
  color: var(--dark-text-color) !important;
}
.WrappedDatePickerInput.NInput {
  width: 100px;
  color: var(--main-accent-color);
  border: 1px solid transparent;
  background-color: transparent;
}