.QuestionTooltip {
    min-width: 14px;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    color: #45455A;
    border: 1px solid #45455A;
    border-radius: 50%;
    font-size: 10px;
    /*position: relative;*/
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}
.QuestionTooltip__text {
    position: absolute;
    z-index: 20;
    width: 0;
    right: 7px;
    bottom: 7px;
    border: 1px solid #45455A;
    border-radius: 4px;
    padding: 0;
    color: #6D6D6D;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    text-align: left;
    transition: all .1s ease;
    overflow: hidden;
    height: 0;
    opacity: 0;
}
.QuestionTooltip:hover {
    position: relative;
}
.QuestionTooltip:hover .QuestionTooltip__text {
    right: 14px;
    bottom: 14px;
    width: 250px;
    padding: 10px;
    white-space: normal;
    height: auto;
    opacity: 1;
}

.QuestionTooltip__text.QuestionTooltip__text_right-bottom {
    right: unset;
    bottom: unset;
}
.QuestionTooltip:hover .QuestionTooltip__text.QuestionTooltip__text_right-bottom {
    right: unset;
    bottom: unset;
    left: 14px;
    top: 14px;
}
.QuestionTooltip__text.QuestionTooltip__text_right {
    right: unset;

}
.QuestionTooltip:hover .QuestionTooltip__text.QuestionTooltip__text_right {
    right: unset;
    left: 14px;
}
.QuestionTooltip.QuestionTooltip_w350:hover .QuestionTooltip__text {
    width: 350px;
}
.QuestionTooltip.QuestionTooltip_w580:hover .QuestionTooltip__text {
    width: 580px;
}
.QuestionTooltip__text.QuestionTooltip__text_z9000 {
    z-index: 9000;
}
.NHeading3 .QuestionTooltip,
.NHeading3 .QuestionTooltip__text {
    font-weight: normal;
}
.NHeading3 .QuestionTooltip {
    margin-left: 4px;
}
.QuestionTooltip__more-link {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: var(--main-accent-color);
    cursor: pointer;
}

.NSwitcher__tab.NSwitcher__tab_active .QuestionTooltip {
    color: var(--btn-accent-color);
    border-color: var(--btn-accent-color);
}
