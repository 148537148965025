.ParamFormGroup {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.DnD-item-container .ParamFormGroup {
  margin-top: calc(2*var(--distance-unit));
}
.ParamFormGroup .NFormGroup {
  width: 100%;
}
.ParamFormGroup .BtnDel {
  align-self: flex-end;
  opacity: 0;
}
.DnD-ico-container {
  height: 16px;
  width: 10px;
  background: url("./../../../../assets/v3-DnD-ico.svg") no-repeat center / contain;
  cursor: unset;
  align-self: flex-end;
  margin-bottom: 12px;
  opacity: 0;
}
.ParamFormGroup:hover .DnD-ico-container,
.ParamFormGroup:hover .BtnDel {
  opacity: 1;
  cursor: pointer;
}
.ParamFormGroup.ParamFormGroup_DnDisForbidden:hover .DnD-ico-container {
  opacity: 0;
  cursor: unset;
}