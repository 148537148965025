.NCard {
  background-color: #FFFFFF;
  /*box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);*/
  border-radius: var(--card-border-radius);
  width: 100%;
  /*max-width: var(--content-width);*/
  border: 1px solid var(--sidebar-border-color);
}
.NModal_body .NCard {
  max-width: var(--content-width);
}
.NCard.NCard_w-420{
  width:420px;
  min-width:420px;
}
.NCard.NCard_hideBackCard {
  background-color: unset;
  box-shadow: unset;
  padding: 0;
  outline: 1px dashed var(--sec-text-soft-color);
}
.NCard.NCard__wide-width {
  width: 100%;
  max-width: var(--wide-content-width);
}
.NCard.NCard_embeddedMode {
  box-shadow: unset;
  border: 1px solid var(--btn-del-grey-color);
  border-radius: var(--border-radius);
  padding: calc(2 * var(--distance-unit));
}
.NCard.NCard_error {
  background-color: var(--alert-back-error-color);
}
.NCard_items + .NCard_items {
  margin-top: calc(2 * var(--distance-unit));
}