.EmptyList{
     height: 140px;
     width: 100%;
    max-width: var(--wide-content-width);
     border: 1px dashed #E0E3E9;
     border-radius: 10px;
     background-color: rgba(224,227,233,0.3);
    display: flex;
    justify-content: flex-end;
}
.EmptyList.EmptyList_searchResultsMode {
    justify-content: center;
}
.EmptyList__img {
    width: 210px;
    background: url("./../../../../assets/empty-list-img.png") no-repeat center;
}
.EmptyList__text-container{
    align-self: center;
}
.EmptyList__title{
    color: #3A434D;
    font-family: var(--heading-font-family);
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
}
.EmptyList__text{
    color: #3A434D;
    font-family: var(--heading-font-family);
    font-size: 14px;
    line-height: 18px;
}
.EmptyList__rel-container{
    width: 230px;
    height: 36px;
    /*border: 1px solid cadetblue;*/
    position: relative;
    align-self: center;
}
.EmptyList__arrow-img {
    width: 230px;
    height: 110px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("./../../../../assets/empty-list-arrow.svg") no-repeat center / contain;
}
@media (max-width: 1100px) {
    .EmptyList__img {
        display: none;
    }
    .EmptyList {
        padding-left: calc(3*var(--distance-unit));
    }
}
@media (max-width: 900px) {
    .EmptyList__rel-container {
        display: none;
    }
    .EmptyList {
        justify-content: center;
    }
}