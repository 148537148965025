.NModal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8000;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.NModal_body{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.5);
  padding: 5vh 0;
}
.NModal__wrapper.NModal__wrapper_hide {
  display: none;
}
.NModal {
  width: 100%;
}
.NModal__btns-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.NModal__btns-container-border-top{
  padding-top:calc(4 * var(--distance-unit));
  margin-top:calc(3 * var(--distance-unit));
  border-top: 1px solid var(--form-field-border-color);
}
.NModal.NModal_ov {
  overflow: visible;
}
.NModal.NModal_has_NCard .NCard {
  max-width: 100%;
  width: auto;
}
.NModal.NModal_ViewTemplateParams,
.NModal.NModal_ViewTemplateParams .NCard {
  width: 1024px;
  max-width: 1024px;
}
.NModal__contentOverflowScroll {
  max-height: calc(100vh - 2 * (24px + 60px + 16px) - 70px);
  overflow-y: scroll;
  margin: calc(2 * var(--distance-unit)) 0;
}
.NModal_min-w-content {
  min-width: var(--content-width);
  margin: auto;
}

@media (max-width: 1050px) {
  .NModal.NModal_ViewTemplateParams,
  .NModal.NModal_ViewTemplateParams .NCard {
    min-width: 90%;
    width: 90%;
    max-width: 90%;
  }
}
@media (max-width: 850px) {
  .NCard.NModal_min-w-content {
    min-width: 90%;
    width: 90%;
    max-width: 90%;
  }
  .NCard.NCard_w-420.NModal_min-w-content{
    width:420px;
    min-width:420px;
  }
}