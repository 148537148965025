.AlertCard {
  min-width: 221px;
  max-width: 500px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.AlertCard__header {
  height: 54px;
  display: flex;
  align-items: center;
  line-height: 54px;
  color: var(--btn-accent-color);
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px 8px 0px 0px;
  padding: 0 calc(2*var(--distance-unit));
}
.AlertCard__header.AlertCard__header_rounded {
  border-radius: 8px;
}
.AlertCard__header.AlertCard__header_in_ReportTotalExpression {
  font-weight: normal;
  line-height: 1;
  height: auto;
  font-size: 12px;
  padding: calc(1*var(--distance-unit));
}
.AlertCard__header-ico {
  margin-right: 25px;
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.AlertCard__header.AlertCard__header_in_ReportTotalExpression .AlertCard__header-ico {
  margin-right: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
}
.AlertCard__header-title a {
  display: inline;
}
.AlertCard__header-close-ico {
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;
}
/*export type alertType = 'ERROR' | 'SUCCESS' | 'WARNING' | 'NOTIFICATION';*/
.AlertCard__header.AlertCard__header_ERROR {
  background-color: var(--alert-back-error-color);
}
.AlertCard__header.AlertCard__header_SUCCESS {
  background-color: var(--alert-back-success-color);
}
.AlertCard__header.AlertCard__header_WARNING,
.AlertCard__header.AlertCard__header_REPORT-WARNING {
  background-color: var(--alert-back-warning-color);
}
.AlertCard__header.AlertCard__header_NOTIFICATION {
  background-color: var(--alert-back-notification-color);
}
.AlertCard__header.AlertCard__header_ERROR .AlertCard__header-title{
  color: var(--alert-error-color);
}
.AlertCard__header.AlertCard__header_SUCCESS .AlertCard__header-title{
  color: var(--alert-success-color);
}
.AlertCard__header.AlertCard__header_WARNING .AlertCard__header-title{
  color: var(--alert-warning-color);
}
.AlertCard__header.AlertCard__header_REPORT-WARNING .AlertCard__header-title{
  color: var(--alert-report-warning-color);
}
.AlertCard__header.AlertCard__header_NOTIFICATION .AlertCard__header-title{
  color: var(--alert-notification-color);
}
.AlertCard__header.AlertCard__header_ERROR .AlertCard__header-ico {
  background: url("./../../../../assets/alert-error-ico.svg") no-repeat center / 100%;
}
.AlertCard__header.AlertCard__header_SUCCESS .AlertCard__header-ico {
  background: url("./../../../../assets/alert-success-ico.svg") no-repeat center / 100%;
}
.AlertCard__header.AlertCard__header_WARNING .AlertCard__header-ico {
  background: url("./../../../../assets/alert-warning-ico.svg") no-repeat center / 100%;
}
.AlertCard__header.AlertCard__header_REPORT-WARNING .AlertCard__header-ico {
  background: url("./../../../../assets/alert-report-warning-ico.svg") no-repeat center / 100%;
}
.AlertCard__header.AlertCard__header_NOTIFICATION .AlertCard__header-ico {
  background: url("./../../../../assets/alert-notification-ico.svg") no-repeat center / 100%;
}
.AlertCard__header.AlertCard__header_ERROR .AlertCard__header-close-ico {
  background: url("./../../../../assets/alert-error-close-ico.svg") no-repeat center / auto;
}
.AlertCard__header.AlertCard__header_SUCCESS .AlertCard__header-close-ico {
  background: url("./../../../../assets/alert-success-close-ico.svg") no-repeat center / auto;
}
.AlertCard__header.AlertCard__header_WARNING .AlertCard__header-close-ico {
  background: url("./../../../../assets/alert-warning-close-ico.svg") no-repeat center / auto;
}
.AlertCard__header.AlertCard__header_NOTIFICATION .AlertCard__header-close-ico {
  background: url("./../../../../assets/alert-notification-close-ico.svg") no-repeat center / auto;
}
.AlertCard__body {
  padding: calc(2*var(--distance-unit));
  max-height: 50vh;
  overflow: auto;
}