.Heading4 {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--dark-text-color);
}
.Heading4.Heading4_lh16 {
  line-height: 16px;
}
.Heading4.Heading4_lh40 {
  line-height: 40px;
}
.Heading4.Heading4_link-color {
  color: var(--main-accent-color);
  cursor: pointer;
}
.Heading4.Heading4_main-accent-color {
  color: var(--main-accent-color);
}
.Heading4.Heading4_card-label-color {
  color: var(--card-label-color);
}
a:hover .Heading4.Heading4_card-label-color {
  color: var(--main-accent-color);
}
.Heading4.Heading4_link-color:hover {
  text-decoration: underline;
}
.Heading4.Heading4_error {
  color: var(--error-color);
}
.Heading4.Heading4_fwb {
  font-weight: bold;
}
.Heading4.Heading4_fsi {
  font-style: italic;
}
.Heading4.Heading4_lh20 {
  line-height: 20px;
}
.Heading4.Heading4_lh22 {
  line-height: 22px;
}
.Heading4.Heading4_link-on-hover:hover {
  color: var(--main-accent-color);
}