.FormTagSelect {
  /*width: 80%;*/
}
.FormTagSelect__enter-ico {
  width: 24px;
  height: 24px;
  background: url("./../../../../assets/enter.svg") center / contain no-repeat;
}
.FormTagSelect__enter-ico.FormTagSelect__enter-ico_hidden {
  opacity: 0;
}
.FormTagSelect__clear-all {
  display: block;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--warning-color);
  position: absolute;
  left: 52px;
  top: -12px;
  cursor: pointer;
}
.FormTagSelect__clear-all-container {
  position: relative;
}