.DropMenuComponents {

}
.DropMenuComponents_shiftedRightForUiKit {
    position: relative;
    left: 300px;
}
.DropMenuComponents__menuLabel {
    width: 100%;
    height: var(--el-height);
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    padding: 0 calc(2*var(--distance-unit));
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    color: var(--main-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
}
.DropMenuComponents__menuLabel:hover {
    background-color: var(--btn-del-grey-color);
    color: var(--menu-item-back-hover-color);
}

.DropMenuComponents__menuLabelArrow {
    margin-right: var(--distance-unit);
    font-weight: 600;
    font-family: monospace;
}
.DropMenuComponents__componentContainer {
    position: absolute;
    top: 0;
    right: 100%;
    /*border: 1px solid black;*/
    display: none;
    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.03), 0px 3px 3px rgba(0, 0, 0, 0.02), 0px 7px 6px rgba(0, 0, 0, 0.03), 0px 12px 10px rgba(0, 0, 3, 0.03), 0px 22px 18px rgba(0, 0, 0, 0.04), 0px 40px 33px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);
}
.DropMenuComponents__menuLabel:hover .DropMenuComponents__componentContainer {
    display: block;
}