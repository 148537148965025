.BtnAddRow {
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--item-back-color);
  color: var(--dark-text-color);
  font-size: 16px;
  line-height: 16px;
  padding: 12px;
  text-align: center;
  cursor: pointer;
}
.BtnAddRow:hover {
  color: var(--main-accent-color);
  background-color: var(--item-back-hover-color);
}