.BtnBack {
  position: relative;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.BtnBackLabel {
  position: relative;
  left: 30px;
}