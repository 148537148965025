.CheckboxInput {
  display: flex;
  padding: calc(2*var(--distance-unit)) 0;
  cursor: pointer;
  width: 100%;
}
.CheckboxInput .Checkbox {
  margin-right: calc(2*var(--distance-unit));
}
.CheckboxInput.CheckboxInput_1du-to-ico .Checkbox {
  margin-right: var(--distance-unit);
}
.CheckboxInput__label {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text-color);
}
.CheckboxInput.CheckboxInput_disabled {
  cursor: unset;
  opacity: .5;
}
.CheckboxInput.CheckboxInput_readOnly,
.CheckboxInput.CheckboxInput_disabled .Checkbox,
.CheckboxInput.CheckboxInput_readOnly .Checkbox{
  cursor: unset;
}
.CheckboxInput.CheckboxInput_fs12 .CheckboxInput__label {
  font-size: 12px;
  line-height: 16px;
}
.CheckboxInput.CheckboxInput_fs12 {
  padding: calc(1*var(--distance-unit)) 0;
}
.CheckboxInput.CheckboxInput_fs12 .Checkbox {
  height: 16px;
  width: 16px;
  margin-right: calc(2*var(--distance-unit));
}