.SecText {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--sec-text-color);
}
.SecText.SecText_lh16 {
  line-height: 16px;
}
.SecText.SecText_lh24 {
  line-height: 24px;
}