.ButtonTooltipContainer {
    position: relative;
}
.button-tooltip{
    display:none;
    position:absolute;
    z-index:9999;
    font-size:14px;
    color:#fff;
    background-color: #000;
    padding:5px;
    border-radius:3px;
    max-width:100px;
    transform: translateX(-50%);
    left:50%;
    text-align: center;
}
.ButtonTooltipContainer.ButtonTooltipContainer_medium .button-tooltip {
    max-width: 200px;
}
.ButtonTooltipContainer.ButtonTooltipContainer_BtnDel .button-tooltip {
    max-width: 200px;
    min-width: 200px;
    left: 90%;
    transform: translateX(-103%);
}
.button-tooltip:after{
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content:'';
    width:0;
    height:0;
}
.ButtonTooltipContainer.ButtonTooltipContainer_BtnDel .button-tooltip:after {
    left: 90%;
}
/*.button-tooltip.button-tooltip_show{*/
.ButtonTooltipContainer:hover .button-tooltip {
    display:block;
}
.button-tooltip.button-tooltip_top{
    top:-40px;
}
.button-tooltip.button-tooltip_top:after{
    bottom:-5px;
    border-top:5px solid #000;
}
.button-tooltip.button-tooltip_top-24{
    top:-24px;
}
.button-tooltip.button-tooltip_top-24:after{
    bottom:-5px;
    border-top:5px solid #000;
}
.button-tooltip.button-tooltip_16-from-bottom{
    bottom: 20px;
}
.button-tooltip.button-tooltip_16-from-bottom:after{
    bottom:-5px;
    border-top:5px solid #000;
}
.button-tooltip.button-tooltip_bottom{
    bottom:-40px;
}
.button-tooltip.button-tooltip_bottom:after{
    top:-5px;
    border-bottom:5px solid #000;
    width:0;
    height:0;
}
.ButtonTooltipContainer.ButtonTooltipContainer_withTagToCopy {
    width: fit-content;
}
.ButtonTooltipContainer.ButtonTooltipContainer_withTagToCopy .button-tooltip {
    max-width: 150px;
    width: 150px;
}

.ListItemCard__buttons .ButtonTooltipContainer {
    width: 100%;
    height: 50%;
}