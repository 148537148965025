.Logo{
  font-family: var(--logo-font-family);
  font-size: 21px;
  font-weight: 900;
  line-height: 21px;
  letter-spacing: normal;
  color: #000;
  cursor: pointer;
}
.Logo__blue{
  color: var(--main-accent-color);
}
.Logo__small, .Layout__Compact .Logo__full {
  display: none;
}
.Layout__Compact .Logo__small {
  display: block;
}