/*utility classes*/
/*1du means value of --distance-unit*/
.pl-pr_2du {
  padding-left: calc(2*var(--distance-unit));
  padding-right: calc(2*var(--distance-unit));
}
.pr_2du {
  padding-right: calc(2*var(--distance-unit));
}
.pl_6du{
  padding-left: calc(6*var(--distance-unit));
}
.pl-pr_3du {
  padding-left: calc(3*var(--distance-unit));
  padding-right: calc(3*var(--distance-unit));
}
.p_3du {
  padding: calc(3*var(--distance-unit));
}
.pt_1du {
  padding-top: var(--distance-unit);
}
.pl_2du {
  padding-left: calc(2*var(--distance-unit));
}
.pl_3du {
  padding-left: calc(3*var(--distance-unit));
}
.pb_1du {
  padding-bottom: var(--distance-unit);
}
.pb_2du {
  padding-bottom: calc(2*var(--distance-unit));
}
.pt_3du {
  padding-top: calc(3*var(--distance-unit));
}
.pt-pb_2du {
  padding-top: calc(2*var(--distance-unit));
  padding-bottom: calc(2*var(--distance-unit));
}
.pt-pb_1du {
  padding-top: var(--distance-unit);
  padding-bottom: var(--distance-unit);
}
.pr_3du {
  padding-right: calc(3*var(--distance-unit));
}
.p_1du_3du {
  padding: var(--distance-unit) calc(3*var(--distance-unit));
}
.p_6du_4du_5du {
  padding: calc(6*var(--distance-unit)) calc(4*var(--distance-unit)) calc(5*var(--distance-unit));
}
.p_2du{
  padding:calc(2*var(--distance-unit));
}
.pt_0{
  padding-top:0;
}
.pb_0{
  padding-bottom:0;
}
.mb_half-du {
  margin-bottom: calc(var(--distance-unit)/2);
}
.mb_1du {
  margin-bottom: var(--distance-unit);
}
.mb_1halfdu {
  margin-bottom: calc(1.5*var(--distance-unit));
}
.mb_2du {
  margin-bottom: calc(2*var(--distance-unit));
}
.mb_3du {
  margin-bottom: calc(3*var(--distance-unit));
}
.mb_4du {
  margin-bottom: calc(4*var(--distance-unit));
}
.mb_5du {
  margin-bottom: calc(5*var(--distance-unit));
}
.mb_6du {
  margin-bottom: calc(6*var(--distance-unit));
}
.mb_60 {
  margin-bottom: 60px;
}
.mt_quarter-du {
  margin-top: calc(var(--distance-unit)/4);
}
.mt_half-du {
  margin-top: calc(var(--distance-unit)/2);
}
.mt_1du {
  margin-top: var(--distance-unit);
}
.mt_2du {
  margin-top: calc(2*var(--distance-unit));
}
.mt_3du {
  margin-top: calc(3*var(--distance-unit));
}
.mt_4du {
  margin-top: calc(4*var(--distance-unit));
}
.mt_5du {
  margin-top: calc(5*var(--distance-unit));
}
.mt_6du {
  margin-top: calc(6*var(--distance-unit));
}
.mr_half-du {
  margin-right: calc(var(--distance-unit)/2);
}
.mr_1du {
  margin-right: var(--distance-unit);
}
.mr_2du {
  margin-right: calc(2*var(--distance-unit));
}
.mr_3du {
  margin-right: calc(3*var(--distance-unit));
}
.mr_4du {
  margin-right: calc(4*var(--distance-unit));
}
.mr_6du {
  margin-right: calc(6*var(--distance-unit));
}
.ml_half-du {
  margin-left: calc(var(--distance-unit)/2);
}
.ml_0{
  margin-left:0;
}
.ml_1du {
  margin-left: var(--distance-unit);
}
.ml_2du {
  margin-left: calc(2*var(--distance-unit));
}
.ml_3du {
  margin-left: calc(3*var(--distance-unit));
}
.ml_4du {
  margin-left: calc(4*var(--distance-unit));
}
.ml_5du {
  margin-left: calc(5*var(--distance-unit));
}
.ml_6du {
  margin-left: calc(6*var(--distance-unit));
}
.mlr_-3du {
  margin-left: calc(-3*var(--distance-unit));
  margin-right: calc(-3*var(--distance-unit));
}
.mt_-1du {
  margin-top: calc(-1*var(--distance-unit));
}
.mb_-1du {
  margin-bottom: calc(-1*var(--distance-unit));
}
.mt_-2du {
  margin-top: calc(-2*var(--distance-unit));
}
.m_a {
  margin: auto;
}
.ml_a {
  margin-left: auto;
}
.mtb_2px {
  margin-top: 2px;
  margin-bottom: 2px;
}
.ml_2px {
  margin-left: 2px;
}
.ta_c {
  text-align: center;
}
.ta_r {
  text-align: right;
}
.ws_n {
  white-space: nowrap;
}

.flex {
  display: flex;
}
.flex_jc-sb {
  display: flex;
  justify-content: space-between;
}
.flex_jc-sb_900adapt {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 900px) {
  .flex_jc-sb_900adapt {
    display: block;
  }
  .mb_3du_900adapt {
    margin-bottom: calc(3*var(--distance-unit));
  }
}
.ai_fe {
  align-items: flex-end;
}
.ai_c {
  align-items: center;
}
.jc_fe {
  justify-content: flex-end;
}
.jc_c {
  justify-content: center;
}
.as_fe {
  align-self: flex-end;
}
.as_c {
  align-self: center;
}
.flex_wrap {
  flex-wrap: wrap;
}

.max-w_quarter-card {
  max-width: var(--quarter-card-width);
}
.max-w_card {
  max-width: 784px;
}
.modal_width_sm {
  max-width: 300px !important;
  min-width: 120px !important;
}
.w_quarter-card {
  width: var(--quarter-card-width);
  max-width: var(--quarter-card-width);
}
.w_half-card {
  width: var(--half-card-width);
  max-width: var(--half-card-width);
}
.w_three-quarter-card {
  width: var(--three-quarter-card-width);
  max-width: var(--three-quarter-card-width);
}
.w_wide-content {
  width: 100%;
  max-width: var(--wide-content-width);
}
.w_content {
  width: 100%;
  max-width: var(--content-width);
}
.min-w_content {
  min-width: var(--content-width);
}
.w_49 {
  width: 49%;
}
.w_100 {
  width: 100%;
}
.w_448 {
  width: 448px;
}
.w_628 {
  width: 628px;
}
.w_32per {
  width: 32.5%;
}
.wb_ba {
  word-break: break-all;
}

.button-wrapper-link {
  display: block;
}

.fixed-on-right-side {
  position: fixed;
  left: calc(var(--sidebar-width) + var(--sidebar-right-margin) + var(--content-width) + 3*var(--distance-unit));
  top: calc(var(--header-height) + 6*var(--distance-unit));
  max-height: calc(100vh - var(--header-height) - 8*var(--distance-unit));
  overflow-y: scroll;
}

.clearfix:after{
  content:'';
  clear:both;
  display:table;
}
.ov_h {
  overflow: hidden;
  position: fixed;
  -webkit-overflow-scrolling: auto;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}
.back-for-set-pass {
  background-color: var(--body-back-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.pos_rel {
  position: relative;
}
.pos_abs {
  position: absolute;
}
.pos_sticky {
  position: -webkit-sticky;
  position: sticky;
}
.z_20 {
  z-index: 20;
}
.z_1000 {
  z-index: 1000;
}
.z_1100 {
  z-index: 1100;
}
.z_2000 {
  z-index: 2000;
}
.d_ib {
  display: inline-block;
}
.ReactCollapse--collapse {
  transition: height .5s ease;
}
.for-tags-right-border {
  height: 100%;
}
.tt_uc {
  text-transform: uppercase;
}
.min-h_16 {
  min-height: 16px;
}
.min-h_22 {
  min-height: 22px;
}
.td_u_onHover:hover {
  text-decoration: underline;
}
.link-color {
  color: var(--main-accent-color);
}