.SectionHeader {
  /*border-bottom: 1px solid var(--header-border-color);*/
  padding-top: var(--distance-unit);
  /*padding-bottom: calc(3*var(--distance-unit));*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.SectionHeader.SectionHeader_border-bottom{
  padding-bottom:calc(2 * var(--distance-unit));
  margin-bottom:calc(3 * var(--distance-unit));
  border-bottom: 1px solid var(--form-field-border-color);
  padding-top: 0;
}