.UserBar {
  /*position: fixed;*/
  /*left: 0;*/
  /*bottom: calc(4*var(--distance-unit));*/
  /*margin: 0 calc(4*var(--distance-unit)) calc(1*var(--distance-unit));*/
  /*padding: calc(2*var(--distance-unit)) 0;*/
  /*width: calc(100% - 8*var(--distance-unit));*/

  /*border-top: 1px solid var(--sidebar-border-color);*/
}
.UserBar__userName-container {
  padding: calc(2*var(--distance-unit)) 0;
  /*border-bottom: 1px solid  var(--sidebar-border-color);*/
  border-top: 1px solid  var(--sidebar-border-color);
}
.UserBar__userName {
  /*width: calc(100% - 4*var(--distance-unit));*/
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  text-transform: uppercase;
  color: var(--main-text-color);
}
.UserBar__icon {
  margin: 0 auto var(--distance-unit);
  width: 24px;
  height: 24px;
  background: url("./../../../../assets/user-icon.svg") no-repeat center / contain;
}

.UserBar__logoutContainer {
  margin: 0 auto var(--distance-unit);
  width: 24px;
  height: 24px;
}
.UserBar__logoutContainer .BtnLogout,
.UserBar__logoutContainer .BtnLogout .button__icon{
  width: 24px;
  height: 24px;
}