.Spinner {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    line-height: 100vh;
    background-color: rgba(256, 256, 256, .5);
}
/*.Spinner__img {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    width: 60px;*/
/*}*/

.Spinner__balls {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    height: 40px;
    width: 40px;
    transform: translate(-50%, -50%);
}

[class^="ball-"] {
    position: absolute;
    display: block;
    left: 30px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all 0.5s;
    animation: circleRotate 4s both infinite;
    transform-origin: 0 250% 0;
    background-color: var(--main-accent-color);
}

@keyframes circleRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(1440deg);
    }
}

.ball-1 {
    z-index: -1;
    animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
}
.ball-2 {
    z-index: -2;
    animation-timing-function: cubic-bezier(0.5, 0.6, 0.9, 0.9);
}
.ball-3 {
    z-index: -3;
    animation-timing-function: cubic-bezier(0.5, 0.9, 0.9, 0.9);
}
.ball-4 {
     z-index: -4;
     animation-timing-function: cubic-bezier(0.5, 1.2, 0.9, 0.9);
}
.ball-5 {
      z-index: -5;
      animation-timing-function: cubic-bezier(0.5, 1.5, 0.9, 0.9);
}
.ball-6 {
       z-index: -6;
       animation-timing-function: cubic-bezier(0.5, 1.8, 0.9, 0.9);
}
.ball-7 {
        z-index: -7;
        animation-timing-function: cubic-bezier(0.5, 2.1, 0.9, 0.9);
}
.ball-8 {
         z-index: -8;
         animation-timing-function: cubic-bezier(0.5, 2.4, 0.9, 0.9);
}
