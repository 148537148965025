.Terminals {
    width: 80%;
}
.Terminals__tagSelectContainer {
    width: 80%;
}
.FinTermGate__bottomBorder {
    height: 1px;
    background-color: var(--header-border-color);
    width: 100%;
    margin: calc(3*var(--distance-unit)) 0;
}
.Terminals__option {
    display: flex;
    width: 100%;
    min-width: 100%;
}
.Terminals__option.Terminals__option_p0_12 {
    padding: 0 12px;
}
.Terminals__optionTerminalId {
    width: 100px;
    font-weight: bold;
}
.Terminals__optionGroupFinTerms {
    min-width: calc(100% - 100px);
    max-width: calc(100% - 100px);
}
.Terminals__optionGroupFinTerm {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}