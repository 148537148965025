.ReportCardDownloadDoc .fi {
  margin: 0;
}
.ReportCardDownloadDoc {
  width: 100%;
}
.ReportCardDownloadDoc__fileName {
  max-width: calc(100% - 2*var(--distance-unit) - 36px*40/30);
}
.ReportCardDownloadDoc__file-ico {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  line-height: 1;
  width: calc(36px*40/30);
  height: 40px;
  background: url("./../../../../../../assets/file-type-ico.svg") no-repeat right / contain;
  transition: transform var(--main-transition-time) ease;
}
.ReportCardDownloadDoc__file-ico:hover {
  transform: translateY(-5px);
}
.ReportCardDownloadDoc__fileNameType {
  color: var(--btn-accent-color);
  font-family: var(--icon-font-family);
  text-transform: uppercase;
  font-size: 8px;
  line-height: 1;
  margin-top: 24px;
  text-align: center;
  width: 36px;
}
.ReportCard__docType {
  display: flex;
  font-family: var(--icon-font-family);
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 1;
  color: var(--card-label-color);
  background-color: var(--back-hover-color);
  text-transform: uppercase;
  cursor: pointer;
  height: 50%;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ReportCard__docType.ReportCard__docType_bottom-border {
  border-bottom: 1px solid var(--list-item-inner-border-color);
}
.ReportCard__docType:hover {
  color: var(--main-accent-color);
  text-decoration: underline;
}
@media (max-width: 1000px) {
  .ReportCard__docType {
    min-width: 50%;
    width: 50%;
    max-width: 50%;
    height: 45px;
  }
  .ReportCard__docType.ReportCard__docType_bottom-border {
    border-bottom: unset;
    border-right: 1px solid var(--list-item-inner-border-color);
  }
}