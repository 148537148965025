.ContentPlaceholder {

}
.ContentPlaceholder__placeholder {
    margin: 0 auto;
    max-width: 100%;
    min-height: 150px;
    background-color: #eee;
}
.ContentPlaceholder_98 .ContentPlaceholder__placeholder {
    max-width: 98%;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.ContentPlaceholder__animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 150px;
    position: relative;
}