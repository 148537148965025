.AddAgreementCard__left {
    padding: calc(3 * var(--distance-unit));
    border-right: 1px solid var(--list-item-inner-border-color);
    width: 100%;
    max-width: 100%;
}
.AddAgreementCard__right {
    min-width: 464px;
    max-width: 464px;
    display: flex;
}
.AddAgreementCard__doc-container {
    min-width: 400px;
    max-width: 400px;
    display: flex;
    align-items: center;
}
.AddAgreementCard__doc-container:hover {
    background-color: var(--doc-back-hover-color);
}
.AddAgreementCard__doc-container .Document {
    display: block;
    max-width: 100%;
}
.AddAgreementCard__doc-container .Document__left {
    min-width: 100%;
    max-width: 100%;
}
.AddAgreementCard__doc-container .Document__right {
    min-width: 100%;
    max-width: 100%;
    display: -webkit-flex;
    display: flex;
}
.AddAgreementCard__doc-container .Document__date {
    text-align: left;
    margin-bottom: 0;
    margin-right: 16px;
}
.AddAgreementCard__isNotVerifiedIco {
    width: 16px;
    height: 16px;
    background: url("./../../../../../../../assets/is-not-checked-ico.svg") center / 16px 16px no-repeat;
}
.AddAgreementCard__isVerifiedIco {
    width: 16px;
    height: 16px;
    background: url("./../../../../../../../assets/is-checked-ico.svg") center / 16px 16px no-repeat;
}
@media (max-width: 1100px) {
    .AddAgreementCard.ListItemCard {
        display: block;
    }
    .AddAgreementCard__left {
        border-right: unset;
        border-bottom: 1px solid var(--list-item-inner-border-color);
    }
    .AddAgreementCard__right {
        min-width: 100%;
        max-width: 100%;
    }
    .AddAgreementCard__doc-container {
        min-width: calc(100% - 64px);
        max-width: calc(100% - 64px);
    }
    .AddAgreementCard__doc-container .Document {
        min-width: 100%;
    }
}
