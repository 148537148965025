/*.FormSingleDatePicker {*/
/*  width: var(--quarter-card-width);*/
/*}*/
.SingleDatePickerInput__withBorder {
  border-radius: var(--border-radius);
  border: 1px solid var(--form-field-border-color);
}
.SingleDatePickerInput__withBorder .DateInput {
  background: transparent;
  width: 142px;
}
.DateRangePickerInput_arrow_svg {
  width: 12px;
  height: 12px;
}
.DateInput_input,
.react-datepicker__view-calendar-icon input{
  background-color: transparent;
  line-height: 16px;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--dark-text-color);
  padding: 11px 8px 9px 32px;
  width: 146px;
  border-color: hsl(0,0%,80%);
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
}
.SingleDatePickerInput_calendarIcon {
  margin: 0;
  padding: 10px 8px 8px;
}
.DayPicker__withBorder {
  box-shadow: var(--select-box-shadow);
}
.react-datepicker__input-container .react-datepicker__calendar-icon{
  padding: 0;
  top: 10px;
  left: 8px;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select){
  background:#fff;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
  border-bottom-color:#fff
}
