.Breadcrumbs {
    display: flex;
    flex-wrap: wrap;
}
.Breadcrumbs__item {
    display: flex;
    font-family: var(--heading-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--breadcrumb-color);
}
.Breadcrumbs__item:hover .TextOverflowHandler{
    text-decoration: underline;
}
.Breadcrumbs__item.Breadcrumbs__item_current {
    color: var(--main-accent-color);
    cursor: unset;
}
.Breadcrumbs__item.Breadcrumbs__item_current:hover .TextOverflowHandler{
    text-decoration: unset;
}
