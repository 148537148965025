.HistoryView {
  width: 100%;
  max-width: var(--wide-content-width);
}
.HistoryView.HistoryView_organizationData {
  padding-top: 0;
  margin-top: calc(-3*var(--distance-unit));
}
.HistoryView.HistoryView_organizationData .Filters.Filters_sticky{
  top: calc(var(--header-height) + 80px);
}