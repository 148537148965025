.FilterInput {
    display: flex;
    background-color: var(--filter-select-back-color);
    /*background-color: var(--item-back-color);*/
    border-radius: var(--filter-select-border-radius);
    width: fit-content;
}
.FilterInput__propNameLabel {
    white-space: nowrap;
    margin-right: 4px;
    color: var(--main-text-color);
    font-size: 14px;
    font-family: var(--main-font-family);
    line-height: 40px;
    padding-left: 16px;
    font-weight: 700;
}
.FilterInput .NInput {
    background-color: transparent;
    border-color: transparent;
    width: auto;
    display: flex;
    min-width: 20px;
    color: var(--card-label-color);
    /*font-weight: bold;*/
}