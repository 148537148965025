.ReportTagExpressionRow {
    display: flex;
    margin-bottom: 2px;
}
/*782  = 37+12 + 40+2 + 40+2 + 60+2 + 284+2 + 260+2 + 40              */
.ReportTagExpressionRow__number {
    height: var(--el-height);
    width: 38px;
    margin-right: 12px;
    font-family: var(--heading-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: var(--el-height);
    text-align: right;
    color: var(--card-label-color);
}
.ReportTagExpressionRow__DandD-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--el-height);
    height: var(--el-height);
    background-color: var(--item-back-color);
    cursor: pointer;
    margin-right: 2px;
}
.ReportTagExpressionRow__DandD-btn .DnD-ico-container {
    opacity: 1;
    cursor: pointer;
}
.ReportTagExpressionRow__DandD-btn.isDisabled {
    cursor: unset;
}
.ReportTagExpressionRow__DandD-btn.isDisabled .DnD-ico-container{
    opacity: .25;
    cursor: unset;
}
.ReportTagExpressionRow__operationType {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    margin-right: 2px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__label {
    max-width: 60px;
    min-width: 60px;
    width: 60px;
    margin-right: 2px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__delBtn {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__labelValue {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: var(--el-height);
    color: var(--black-color);
    padding-left: 10px;
}
.ReportTagExpressionRow__tagType {
    max-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 260px + 2px + 40px ));
    min-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 260px + 2px + 40px ));
    width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 260px + 2px + 40px ));
    margin-right: 2px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__tagExpSelect {
    max-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 40px ));
    min-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 40px ));
    width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 40px ));
    margin-right: 2px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__number-input,
.ReportTagExpressionRow__VAT-input {
    max-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 40px ));
    min-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 40px ));
    width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 60px + 2px + 2px + 40px ));
    margin-right: 2px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__number-input .NInput,
.ReportTagExpressionRow__VAT-input .NInput {
    border-radius: 0;
    border: unset;
    background-color: transparent;
}
.ReportTagExpressionRow__finTermsNumbers,
.ReportTagExpressionRow__finTermsNumber {
    max-width: 260px;
    min-width: 260px;
    width: 260px;
    margin-right: 2px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__finTermsNumbers {
    background-color: unset;
}
.ReportTagExpressionRow__finTermsNumbersFrom,
.ReportTagExpressionRow__finTermsNumbersTo {
    max-width: 108px;
    min-width: 108px;
    width: 108px;
    background-color: var(--item-back-color);
}
.ReportTagExpressionRow__finTermsNumbersArrow {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    margin-right: 2px;
    margin-left: 2px;
    background: var(--item-back-color) url("./../../../../../../../../assets/v5-arrow-icon.svg") center / 16px 16px no-repeat;
}
@media (max-width: 1000px) {
    .ReportTagExpressionRow__DandD-btn {
        display: none;
    }
    .ReportTagExpressionRow__tagType {
        max-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 2px + 260px + 2px + 40px ));
        min-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 2px + 260px + 2px + 40px ));
        width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 2px + 260px + 2px + 40px ));
    }
    .ReportTagExpressionRow__label {
        max-width: 40px;
        min-width: 40px;
        width: 40px;
    }
    .ReportTagExpressionRow__tagExpSelect,
    .ReportTagExpressionRow__VAT-input {
        max-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 2px + 40px ));
        min-width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 2px + 40px ));
        width: calc(100% - (37px + 12px + 40px + 2px + 40px + 2px + 2px + 40px ));
    }
}
@media (max-width: 900px) {
    .ReportTagExpressionRow__tagType {
        max-width: calc((100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 2px + 40px ))*0.4);
        min-width: calc((100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 2px + 40px ))*0.4);
        width: calc((100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 2px + 40px ))*0.4);
    }
    .ReportTagExpressionRow__finTermsNumbers,
    .ReportTagExpressionRow__finTermsNumber {
        max-width: calc((100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 2px + 40px ))*0.6);
        min-width: calc((100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 2px + 40px ))*0.6);
        width: calc((100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 2px + 40px ))*0.6);
    }
    .ReportTagExpressionRow__finTermsNumbersFrom,
    .ReportTagExpressionRow__finTermsNumbersTo {
        max-width: calc((100% - 40px - 2px - 2px)/2);
        min-width: calc((100% - 40px - 2px - 2px)/2);
        width: calc((100% - 40px - 2px - 2px)/2);
    }
    .ReportTagExpressionRow__number {
        margin-right: 4px;
        width: 28px;
    }
    .ReportTagExpressionRow__tagExpSelect,
    .ReportTagExpressionRow__VAT-input {
        max-width: calc(100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 40px ));
        min-width: calc(100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 40px ));
        width: calc(100% - (28px + 4px + 40px + 2px + 40px + 2px + 2px + 40px ));
    }
}