.BtnDel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--el-height);
  height: var(--el-height);
  border-radius: var(--border-radius);
  background-color: var(--btn-back-color);
  cursor: pointer;
}
.BtnDel:hover {
  background-color: var(--item-back-hover-color);
}
.BtnDel__ico-container {
  width: 16px;
  height: 16px;
  display: block;
  background: url("./../../../../assets/v3-btn-del-ico.svg") no-repeat center / contain;
}
.BtnDel:disabled {
  cursor: unset;
  opacity: .5;
}
.BtnDel:disabled:hover {
  background-color: var(--btn-back-color);
}
.BtnDel.BtnDel_grey-ico  {
  background-color: var(--btn-del-grey-color);
}
.BtnDel.BtnDel_grey-ico .BtnDel__ico-container {
  background: url("./../../../../assets/v3-btn-del-grey-ico.svg") no-repeat center / contain;
}

.BtnDel.DeleteConvertedTagBtn {
  height: 48px;
  background-color: var(--item-back-color);
}
.BtnDel.DeleteConvertedTagBtn:hover {
  background-color: var(--tag-btn-hover-color);
}

.BtnDel_cardButtonMode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  border-radius: 0;
  background: linear-gradient(0deg, rgba(255, 0, 92, 0.05), rgba(255, 0, 92, 0.05));
  cursor: pointer;
}
.BtnDel_cardButtonMode:hover {
  background: var(--btn-del-hover-color);
}
.BtnDel_cardButtonMode .BtnDel__ico-container {
  background: url("./../../../../assets/v4-btn-del-ico.svg") no-repeat center / contain;
}
.BtnDel_cardButtonMode:hover .BtnDel__ico-container {
  background: url("./../../../../assets/v4-btn-del-ico-hover.svg") no-repeat center / contain;
}
.BtnDel_cardButtonMode:disabled {
  cursor: unset;
  opacity: .5;
}
.BtnDel_cardButtonMode:disabled:hover {
  background: linear-gradient(0deg, rgba(255, 0, 92, 0.05), rgba(255, 0, 92, 0.05));
}
.BtnDel_cardButtonMode:disabled:hover .BtnDel__ico-container {
  background: url("./../../../../assets/v4-btn-del-ico.svg") no-repeat center / contain;
}

.ButtonTooltipContainer .BtnDel_cardButtonMode{
  height: 100%;
}

.BtnDel_tagExpressionHeaderMode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
}
/*.BtnDel.BtnDel_tagExpressionHeaderMode:hover {*/
/*  background-color: transparent;*/
/*}*/
.BtnDel_tagExpressionMode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--el-height);
  height: var(--el-height);
  border-radius: 0;
  background-color: var(--item-back-color);
  cursor: pointer;
}
.BtnDel_tagExpressionMode .BtnDel__ico-container,
.BtnDel_tagExpressionHeaderMode .BtnDel__ico-container {
  background: url("./../../../../assets/v4-btn-del-grey-ico.svg") no-repeat center / contain;
}
.BtnDel_tagExpressionMode .BtnDel__ico-container:hover,
.BtnDel_tagExpressionHeaderMode .BtnDel__ico-container:hover {
  background: url("./../../../../assets/v4-btn-del-ico.svg") no-repeat center / contain;
}