.SearchDropdown__wrapper {
  position: relative;
  z-index: 100;
}
.SearchDropdown {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: none;
  padding: 0 7px 15px 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0 20px 40px 0 rgba(0,0,0,0.1);
  max-height: 400px;
  overflow-y: auto;
}
.SearchDropdown.SearchDropdown_hide {
  display: none;
}
.SearchDropdown.SearchDropdown_empty {
  opacity: 0;
}
.SearchDropdown__category {
  width: 130px;
  color: #9B9B9B;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 39px;
}
.SearchDropdown__list {
  padding: 0 0 15px;
  width: 673px;
}
.SearchDropdown__list-item {
  position: relative;
  color: var(--main-text-color);
  font-family: var(--main-font-family);
  font-size: 14px;
  line-height: 39px;
  border-bottom: 1px solid var(--item-back-color);
  display: block;
  padding-left: 30px;
  cursor: pointer;
}
.SearchDropdown__list-item:last-child {
  border-bottom: none;
}
.SearchDropdown__list-item:hover {
  color: var(--main-accent-color);
  text-decoration: underline;
  border-radius: 5px;
  background-color: var(--item-back-hover-color);

}
.SearchDropdown__list-item-forward-ico {
  display: none;
}
.SearchDropdown__list-item:hover .SearchDropdown__list-item-forward-ico {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  display: block;
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  background: url(../../../../../assets/select-arrow-active.svg) no-repeat center;
}
.SearchDropdown__category-container {
  border-top: 1px solid var(--item-back-color);
  display: flex;
}