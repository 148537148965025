.GroupFinTermBlock.GroupFinTermBlock_with-back .GroupFinTermBlock__section{
  background-color: var(--item-back-color);
  padding: calc(2*var(--distance-unit)) calc(3*var(--distance-unit));
  border-radius: var(--card-border-radius);
}
.GroupFinTermBlock__top-row {
  display: flex;
  justify-content: space-between;
}
.GroupFinTermBlock__top-row .BaseAndAddendumDocDownload {
  display: flex;
}
@media (max-width: 950px) {
  .GroupFinTermBlock__top-row {
    display: block;
  }
  .GroupFinTermBlock__top-row .BaseAndAddendumDocDownload {
    margin-bottom: var(--distance-unit);
  }
}
/*.GroupFinTermBlock.GroupFinTermBlock_with-back .DataListContainer,*/
/*.GroupFinTermBlock.GroupFinTermBlock_with-back .NDropDownDetails__summary-container {*/
/*  background-color: var(--item-back-hover-color);*/
/*}*/
/*.GroupFinTermBlock.GroupFinTermBlock_with-back.GroupFinTermBlock_in_ReportMainDataView {*/
/*  padding: calc(2*var(--distance-unit)) calc(3*var(--distance-unit));*/
/*}*/