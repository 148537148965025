.AlertsContainer {
  position: fixed;
  width: 500px;
  left: 0;
  bottom: calc(9 * var(--distance-unit) + 1px);
  z-index: 8600;
}
.Alerts {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.Alerts .AlertCard {
  margin: calc(3*var(--distance-unit)) var(--distance-unit);
  transition: .5s ease all;
}

.AlertCard.AlertCard_translatedY {
  transform: translateY(200px);
}
.AlertCard.AlertCard_deleted {
  opacity: 0;
}