.DisabledContentContainer {
    position: relative;
    opacity: .5;
}
.DisabledContentContainer__over-screen {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}