.Download1CExportFileButton {
    cursor: pointer;
    width: 109px;
    background: var(--main-accent-color) url("./../../../../../../assets/download-1c-export-file-ico.svg") center / 61px 16px no-repeat;
}
.Download1CExportFileButton:hover {
    background: var(--btn-accent-hover-color) url("./../../../../../../assets/download-1c-export-file-ico.svg") center / 61px 16px no-repeat;
}
.Download1CExportFileButton:disabled {
    opacity: .1;
    cursor: unset;
}
.Download1CExportFileButton:disabled:hover {
    background: var(--main-accent-color) url("./../../../../../../assets/download-1c-export-file-ico.svg") center / 61px 16px no-repeat;
}