.Textarea {
  background-color: #ffffff;
  border-radius: var(--border-radius);
  border: 1px solid var(--form-field-border-color);
  height: var(--header-height);
  padding: 12px;
  line-height: 14px;
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--dark-text-color);
  width: 100%;
  transition: all ease var(--main-transition-time);
  resize: vertical;
}

.ParamFormGroup .Textarea {
  height: var(--el-height);
  padding: 12px;
  line-height: 14px;
  overflow: hidden;
}
.ParamFormGroup .Textarea:focus {
  height: calc(2*var(--el-height));
  overflow: auto;
}