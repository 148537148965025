.EditConvertedTagBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--item-back-color);
  cursor: pointer;
}
.EditConvertedTagBtn:hover {
  background-color: var(--tag-btn-hover-color);
}
.EditConvertedTagBtn__ico-container {
  width: 16px;
  height: 16px;
  display: block;
  background: url("./../../../../assets/edit-convert-tag-btn-ico.svg") no-repeat center / contain;
}
.EditConvertedTagBtn:hover .EditConvertedTagBtn__ico-container {
  background: url("./../../../../assets/edit-convert-tag-btn-hover-ico.svg") no-repeat center / contain;
}
.EditConvertedTagBtn:disabled {
  cursor: unset;
  opacity: .5;
}
.EditConvertedTagBtn:disabled:hover {
  background-color: var(--item-back-color);
}