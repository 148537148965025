.NFormGroup.NFormGroup_invalid.MinMaxParamsValidator .NFormGroup .NInput {
  border: 1px solid var(--form-field-border-color);
}
.NFormGroup.NFormGroup_invalid .NInput,
.NFormGroup.NFormGroup_invalid .NSelect,
.NFormGroup.NFormGroup_invalid .SelectFullCard,
.NFormGroup.NFormGroup_invalid .Textarea,
.NFormGroup.NFormGroup_invalid .input,
.NFormGroup.NFormGroup_invalid .ui-select,
.NFormGroup.NFormGroup_invalid .NSwitcher,
.NFormGroup.NFormGroup_invalid .FullSizeButton,
.NFormGroup.NFormGroup_invalid .BtnAddRow,
.NFormGroup.NFormGroup_invalid .form-select .Select-control,
.NFormGroup.NFormGroup_invalid.MinMaxParamsValidator .MinMaxParamFormGroup .NInput,
.GroupFinTermTemplateParamRowWithBtn .NFormGroup.NFormGroup_invalid,
.NFormGroup.NFormGroup_invalid .SingleDatePickerInput__withBorder {
  border: 1px solid var(--warning-color);
}
.PayoutPeriodComplianceValidator.NFormGroup.NFormGroup_invalid {
  border: 1px solid var(--warning-color);
}
.input-bottom-error {
  position: absolute;
  display: block;
  font-family: var(--main-font-family);
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: var(--warning-color);
}
.AdaptiveFormContainer_twoHalfCardWidthFields__field-container .input-bottom-error {
  position: relative;
}
.NFormGroup__label-with-tooltip {
  display: flex;
}
.NFormGroup__label-with-tooltip .NLabel {
  white-space: nowrap;
  margin-right: 4px;
}
.NFormGroup.NFormGroup--inline-block{
  display:inline-block;
}

.GroupFinTermTemplateParamRowWithBtn .NFormGroup.NFormGroup_invalid {
  margin-bottom: calc(2 * var(--distance-unit));
  position: relative;
  border-radius: var(--card-border-radius);
}
.GroupFinTermTemplateParamRowWithBtn .NFormGroup.NFormGroup_invalid .input-bottom-error
{
  bottom: calc(-2 * var(--distance-unit));
}
.GroupFinTermTemplateParamRowWithBtn .NFormGroup.NFormGroup_invalid .DataListContainer.mb_half-du {
  margin-bottom: 0;
}